import ScheduleDto from "../../../dtos/schedulermodule/ScheduleDto";
import TableMappingReadDto from "../../../dtos/pathcommonmodule/TableMappingReadDto";
import PathSynchroMappingDto from "../../../dtos/pathsynchromodule/PathSynchroMappingDto";
import PathSynchroSetupDto from "../../../dtos/pathsynchromodule/PathSynchroSetupDto";

class GetPathSynchroDetailResult {
    Id: number;
    Name: string;
    Source: TableMappingReadDto | null;
    Target: TableMappingReadDto | null;
    Mappings: PathSynchroMappingDto[];
    Schedule: ScheduleDto;
    Setup: PathSynchroSetupDto;
    LastVersionId: number | null;
    PublishedVersionId: number | null;

    constructor(id: number, name: string, source: TableMappingReadDto | null, target: TableMappingReadDto | null, mappings: PathSynchroMappingDto[], schedule: ScheduleDto, setup: PathSynchroSetupDto, lastVersionId: number | null, publishedVersionId: number | null) {
        this.Id = id;
        this.Name = name;
        this.Source = source;
        this.Target = target;
        this.Mappings = mappings;
        this.Schedule = schedule;
        this.Setup = setup;
        this.LastVersionId = lastVersionId;
        this.PublishedVersionId = publishedVersionId;
    }
}

export default GetPathSynchroDetailResult;
