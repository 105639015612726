import React, { useCallback } from 'react';
import { DataGrid, Selection, Column, FilterRow, Scrolling, SearchPanel } from 'devextreme-react/data-grid';

import ProgressCell from '../progress-grid-cell/ProgressCell';
import StatusCell from '../status-grid-cell/StatusCell';
import ResumeCell from '../resume-grid-cell/ResumeCell';

import CustomStore from 'devextreme/data/custom_store';
import GlobalLoader from '../loader/GlobalLoader';
import JobRunDto from '../../classes/dtos/jobmodule/JobRunDto';

import './RunGrid.css';

interface RunGridProps {
    jobRuns: Array<JobRunDto> | null;
    OnClick(jobId: number, pathId: number, pathTypeId: number): void;
}

const RunGrid: React.FC<RunGridProps> = ({ jobRuns, OnClick }) => {

    const onSelectionChanged = useCallback(
        ({ selectedRowsData }) => {
            if (selectedRowsData.length > 0) {
                OnClick(selectedRowsData[0].Id, selectedRowsData[0].PathId, selectedRowsData[0].PathTypeId);
            }
        },
        [OnClick]
    );

    const customDataSource = new CustomStore({
        key: 'Id',
        load: () => {
            return jobRuns || [];
        }
    });

    if (!jobRuns) {
        return <GlobalLoader />
    }

    return (
        <div className={"BlockCard-RunGrid"}>
            <DataGrid
                id="dataGrid"
                dataSource={customDataSource}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={false}
                hoverStateEnabled={true}
                onSelectionChanged={onSelectionChanged}
                height='calc(100vh - 250px)'
            >
                <Scrolling mode="infinite" />
                <FilterRow visible={true} />
                <SearchPanel visible={true} />
                <Selection mode="single" />
                <Column caption="NAME" dataField="Name" dataType="string" allowSorting={false} />
                <Column caption="TYPE" dataField="PathTypeLabel" dataType="string" allowSorting={false} />
                <Column caption="STARTING TIME" dataField="Start" dataType="datetime" allowSorting={false} width={180} />
                <Column caption="PROGRESSION" dataField="Progress" cellRender={ProgressCell} allowSorting={false} />
                <Column caption="STATUS" dataField="Status" cellRender={StatusCell} allowSorting={false} width={100} />
                <Column caption="DETAILS" dataField="Resume" cellRender={ResumeCell} allowSorting={false} width={360} />
            </DataGrid>
        </div>
    );
};

export default RunGrid;
