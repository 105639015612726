class CustomerListDto {
    Id: number;
    Name: string;
    IsOwner: boolean;
    Status: number;
    StatusLabel: string;
    IsCurrent: boolean;

    constructor(id: number, name: string, isOwner: boolean, status: number, isCurrent: boolean) {
        this.Id = id;
        this.Name = name;
        this.IsOwner = isOwner;
        this.Status = status;
        this.StatusLabel = "";
        switch (status) {
            case 1: this.StatusLabel = "Invited"; break;
            case 2: this.StatusLabel = "Ok"; break;
            case 3: this.StatusLabel = "Revoked"; break;
        }
        this.IsCurrent = isCurrent;
    }
}

export default CustomerListDto;
