import React, { useCallback, useState, useMemo, useRef, useImperativeHandle, forwardRef } from 'react';
import { Popup } from 'devextreme-react/popup';
import { Form, Label, FormRef, GroupItem, SimpleItem, ButtonItem, EmptyItem, RequiredRule, EmailRule } from 'devextreme-react/form';
import { ButtonType } from 'devextreme-react/common';
import 'devextreme-react/text-area';

import LabelTemplate from '../label-template/LabelTemplate';

import CustomerApi from '../../api/CustomerApi';

import UserInvitationDto from '../../classes/dtos/accountmodule/UserInvitationDto';

import './UserInvitationForm.scss';

interface UserInvitationFormProps {
    Invited(): void;
}

export interface UserInvitationFormHandle {
    Open: () => void;
}

const userInvitationDto = new UserInvitationDto(null, null);

const UserInvitationForm: React.FC<UserInvitationFormProps> = forwardRef<UserInvitationFormHandle, UserInvitationFormProps>(({ Invited }, ref) => {

    const formRef = useRef<FormRef>(null);

    const [isOpen, setIsOpen] = useState(false);

    useImperativeHandle(ref, () => ({
        Open() {
            if (formRef.current !== null) {
                const formInstance = formRef.current.instance();
                formInstance.clear();
            }
            setIsOpen(true);
        }
    }));

    const emailEditorOptions = {
        valueChangeEvent: 'keyup'
    };

    const closeDialog = () => {
        setIsOpen(false);
    };

    const popupAttributes = useMemo(() => {
        return {
            id: 'elementId',
            class: 'class-name-popup'
        }
    }, []);

    const handleSubmit = useCallback(async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        var inviteResult = await CustomerApi.InviteUserAsync(userInvitationDto.Email);
        if (inviteResult.IsFailed()) {
            alert(inviteResult.ErrorMessage);
            return;
        }
        Invited();
        setIsOpen(false);
    }, []);

    const inviteButtonOptions = {
        text: 'Send invitation',
        type: 'default' as ButtonType,
        useSubmitBehavior: true,
        width: '100%',
        icon: 'send'
    };

    return (
        <React.Fragment>
            <Popup
                wrapperAttr={popupAttributes}
                visible={isOpen}
                onHiding={closeDialog}
                dragEnabled={false}
                hideOnOutsideClick={false}
                showCloseButton={true}
                showTitle={true}
                title="Let's invite a user to your company !"
                container=".dx-viewport"
                width={600}
                height={320}
            >
                <form onSubmit={handleSubmit}>
                    <div style={{ width: 480, color: '#9393A2', fontSize: 16, fontFamily: 'Manrope', fontWeight: '500', lineHeight: '24px', letterSpacing: 0.32, wordWrap: 'break-word' }}>To go further, please enter a valid email.</div>
                    <Form
                        ref={formRef}
                        formData={userInvitationDto}
                        showColonAfterLabel={true}
                        showValidationSummary={false}
                        validationGroup="pathCreationDtoData"
                    >
                        <GroupItem>
                            <SimpleItem dataField="Email" editorType="dxTextBox" editorOptions={emailEditorOptions} >
                                <Label render={LabelTemplate('rename')} />
                                <RequiredRule message="Email is required." />
                                <EmailRule message="Email is invalid" />
                            </SimpleItem>
                        </GroupItem>
                        <EmptyItem colSpan={2} />
                        <GroupItem>
                            <ButtonItem buttonOptions={inviteButtonOptions} />
                        </GroupItem>
                    </Form>
                </form>
            </Popup>
        </React.Fragment>
    );
});

export default UserInvitationForm;