import Settings from "./Settings";
import { AuthToken } from "./AuthToken";

import ProcessingResult from "../framework/ProcessingResult";

import UserSession from "./UserSession";

import GetPathSynchroDetailResult from "../classes/api/result/pathsynchromodule/GetPathSynchroDetailResult";
import UpdatePathSynchroDetailResult from "../classes/api/result/pathsynchromodule/UpdatePathSynchroDetailResult";
import CreateRequestResult from "../classes/api/result/CreateRequestResult";
import GetRequestPathSynchroResult from "../classes/api/result/pathsynchromodule/GetRequestPathSynchroResult";
import GetPathSynchroCapabilitiesResult from "../classes/api/result/pathsynchromodule/GetPathSynchroCapabilitiesResult";
import TableMappingReadDto from "../classes/dtos/pathcommonmodule/TableMappingReadDto";
import PathSynchroMappingDto from "../classes/dtos/pathsynchromodule/PathSynchroMappingDto";
import ScheduleDto from "../classes/dtos/schedulermodule/ScheduleDto";
import PathSynchroMappingCapabilitiesDto from "../classes/dtos/pathsynchromodule/PathSynchroMappingCapabilitiesDto";
import PathSynchroMappingCapabilityDto from "../classes/dtos/pathsynchromodule/PathSynchroMappingCapabilityDto";
import PathSynchroSetupDto from "../classes/dtos/pathsynchromodule/PathSynchroSetupDto";
import TransformationCapabilitiesDto from "../classes/dtos/transformationmodule/TransformationCapabilitiesDto";
import TransformationOptionsDto from "../classes/dtos/transformationmodule/TransformationOptionsDto";
import TransformationOptionDto from "../classes/dtos/transformationmodule/TransformationOptionDto";
import TransformationLinkDto from "../classes/dtos/transformationmodule/TransformationLinkDto";
import TransformationNotConfigurableDto from "../classes/dtos/transformationmodule/TransformationNotConfigurableDto";
import FieldReferenceIdAndKeyDto from "../classes/dtos/FieldReferenceIdAndKeyDto";
import FieldTypeReferenceDto from "../classes/dtos/FieldTypeReferenceDto";

const PathSynchroApi = {

    GetPathSynchroDetailAsync: async function (pathId: number): Promise<ProcessingResult<GetPathSynchroDetailResult>> {
        let authSession = await AuthToken.get();

        var customerId = await UserSession.EnsureAsync(authSession.Token);

        const response = await fetch(Settings.api_url + "/PathSynchro/GetPathSynchroDetail?customerId=" + customerId.toString() + "&pathId=" + pathId.toString(), {
            method: "Get",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            }
        });

        if (response.status === 200) {
            var data = await response.json();
            var source = data.source != null ? new TableMappingReadDto(data.source.dataSourceId, data.source.dataSourceLabel, data.source.databaseReferenceId, data.source.databaseReferenceLabel, data.source.tableReferenceId, data.source.tableReferenceLabel) : null;
            var target = data.target != null ? new TableMappingReadDto(data.target.dataSourceId, data.target.dataSourceLabel, data.target.databaseReferenceId, data.target.databaseReferenceLabel, data.target.tableReferenceId, data.target.tableReferenceLabel) : null;
            var mappings = data.mappings.map((e: any) => new PathSynchroMappingDto(e.isKey, e.sourceFieldReferenceId, e.targetFieldReferenceId, e.transformationLogicId, e.transformationParameters));
            var schedule = new ScheduleDto(data.schedule.isActive, data.schedule.scheduleType, data.schedule.interval, data.schedule.time);
            var setup = new PathSynchroSetupDto(data.setup.deletionActivated);
            return ProcessingResult.Ok(new GetPathSynchroDetailResult(data.id, data.name, source, target, mappings, schedule, setup, data.lastVersionId, data.publishedVersionId));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    },

    UpdatePathSynchroDetailAsync: async function (pathId: number, name: string, mappings: PathSynchroMappingDto[], sourceDataSourceId: number | null, sourceDatabaseReferenceId: number | null, sourceTableReferenceId: number | null, targetDataSourceId: number | null, targetDatabaseReferenceId: number | null, targetTableReferenceId: number | null, schedule: ScheduleDto, setup: PathSynchroSetupDto, publish: boolean): Promise<ProcessingResult<UpdatePathSynchroDetailResult>> {
        let authSession = await AuthToken.get();

        let time = (schedule.Time / (60 * 1000)) - new Date().getTimezoneOffset();

        const response = await fetch(Settings.api_url + "/PathSynchro/UpdatePathSynchroDetail", {
            method: "Post",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            },
            body: JSON.stringify({
                'customerId': 0,
                'pathId': pathId,
                'name': name,
                'sourceDataSourceId': sourceDataSourceId,
                'sourceDatabaseReferenceId': sourceDatabaseReferenceId,
                'sourceTableReferenceId': sourceTableReferenceId,
                'targetDataSourceId': targetDataSourceId,
                'targetDatabaseReferenceId': targetDatabaseReferenceId,
                'targetTableReferenceId': targetTableReferenceId,
                'mappings': mappings.map((e) => { return { isKey: e.IsKey, sourceFieldReferenceId: e.SourceFieldReferenceId, targetFieldReferenceId: e.TargetFieldReferenceId, transformationLogicId: e.TransformationLogicId, transformationParameters: e.TransformationParameters } }),
                'schedule': {
                    'isActive': schedule.IsActive,
                    'scheduleType': schedule.Type,
                    'interval': schedule.Interval,
                    'time': time
                },
                'setup': {
                    deletionActivated: setup.DeletionActivated
                },
                'Publish': publish
            })
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(new UpdatePathSynchroDetailResult(data.id));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    },

    CreateRequestPathSynchroAsync: async function (pathId: number): Promise<ProcessingResult<CreateRequestResult>> {
        let authSession = await AuthToken.get();

        var customerId = await UserSession.EnsureAsync(authSession.Token);

        const response = await fetch(Settings.api_url + "/PathSynchro/CreateRequestPathSynchro", {
            method: "Post",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            },
            body: JSON.stringify({
                'customerId': customerId,
                'pathId': pathId
            })
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(new CreateRequestResult(data.accepted, data.token));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    },

    GetRequestPathSynchroAsync: async function (taskId: string): Promise<ProcessingResult<GetRequestPathSynchroResult>> {
        let authSession = await AuthToken.get();

        var customerId = await UserSession.EnsureAsync(authSession.Token);

        const response = await fetch(Settings.api_url + "/PathSynchro/GetRequestPathSynchro?CustomerId=" + customerId + "&TaskId=" + taskId, {
            method: "Get",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            }
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(new GetRequestPathSynchroResult(data.status, data.progress, data.errorCode));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    },

    GetPathSynchroCapabilitiesAsync: async function (tableReferenceSourceId: number, tableReferenceTargetId: number): Promise<ProcessingResult<GetPathSynchroCapabilitiesResult>> {
        let authSession = await AuthToken.get();

        var customerId = await UserSession.EnsureAsync(authSession.Token);

        const response = await fetch(Settings.api_url + "/PathSynchro/GetPathSynchroCapabilities?CustomerId=" + customerId + "&TableReferenceSourceId=" + tableReferenceSourceId + "&TableReferenceTargetId=" + tableReferenceTargetId, {
            method: "Get",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            }
        });

        if (response.status === 200) {
            var data = await response.json();
            let sourceFields = data.sourceFields.map((e: any) => new FieldReferenceIdAndKeyDto(e.id, e.sourceKey, e.datasetKey, e.name, new FieldTypeReferenceDto(e.type.type, e.type.isMultiple, e.type.typeLabel, e.type.isNullable, e.type.maxLength, e.type.isReadOnly, e.type.isCalculated, e.type.isBusinessRequired, e.type.originTypeLabel, e.type.linkTableId)));
            let targetFields = data.targetFields.map((e: any) => new FieldReferenceIdAndKeyDto(e.id, e.sourceKey, e.datasetKey, e.name, new FieldTypeReferenceDto(e.type.type, e.type.isMultiple, e.type.typeLabel, e.type.isNullable, e.type.maxLength, e.type.isReadOnly, e.type.isCalculated, e.type.isBusinessRequired, e.type.originTypeLabel, e.type.linkTableId)));
            let mappingsCapabilities = data.mappingsCapabilities.map((e: any) => new PathSynchroMappingCapabilitiesDto(e.targetFieldId, e.capabilities.map((x: any) => new PathSynchroMappingCapabilityDto(x.sourceFieldId, x.transformationLogicIds))));
            let transformationsCapabilities = data.transformationsCapabilities.map((e: any) => PathSynchroApi.Mapper(e));
            return ProcessingResult.Ok(new GetPathSynchroCapabilitiesResult(sourceFields, targetFields, mappingsCapabilities, transformationsCapabilities));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    },

    Mapper: function (e: any): TransformationCapabilitiesDto | null {

        if (e.transformationCapabilitiesType === "TransformationOptions") {
            return new TransformationOptionsDto(e.logicId, e.sourceType, e.targetType, e.label, e.icon, e.transformationCapabilitiesType, e.options.map((x: any) => new TransformationOptionDto(x.id, x.label)));
        }

        if (e.transformationCapabilitiesType === "TransformationNotConfigurable") {
            return new TransformationNotConfigurableDto(e.logicId, e.sourceType, e.targetType, e.label, e.icon, e.transformationCapabilitiesType);
        }

        if (e.transformationCapabilitiesType === "TransformationLink") {
            return new TransformationLinkDto(e.logicId, e.sourceType, e.targetType, e.label, e.icon, e.transformationCapabilitiesType);
        }

        alert(e.transformationCapabilitiesType + " not managed");

        return null;
    }
};

export default PathSynchroApi;