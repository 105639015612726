import Settings from "./Settings";
import { AuthToken } from "./AuthToken";

import ProcessingResult from "../framework/ProcessingResult";

import CustomerListDto from "../classes/dtos/accountmodule/CustomerListDto";

const CustomerApi = {

    GetCustomerListAsync: async function (): Promise<ProcessingResult<CustomerListDto[]>> {
        let authSession = await AuthToken.get();

        const response = await fetch(Settings.api_url + "/Customer/GetCustomers", {
            method: "Get",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            }
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(data.customers.map((e: { id: number; name: string; isOwner: boolean; status: number; isCurrent: boolean; }) => new CustomerListDto(e.id, e.name, e.isOwner, e.status, e.isCurrent)));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());

    },

    SwitchCustomerAsync: async function (targetCustomerId: number): Promise<ProcessingResult<boolean>> {
        let authSession = await AuthToken.get();

        const response = await fetch(Settings.api_url + "/Customer/SwitchCustomer", {
            method: "Post",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            },
            body: JSON.stringify({ 'customerId': 0, 'targetCustomerId': targetCustomerId })
        });

        if (response.status === 200) {
            return ProcessingResult.Ok(true);
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    },

    InviteUserAsync: async function (email: string): Promise<ProcessingResult<number>> {
        let authSession = await AuthToken.get();

        const response = await fetch(Settings.api_url + "/Customer/InviteUser", {
            method: "Post",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            },
            body: JSON.stringify({ 'customerId': 0, 'email': email })
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(data.status);
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    }
};

export default CustomerApi;