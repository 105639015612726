import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from 'devextreme-react';

import PathCreationForm, { PathCreationFormHandle } from '../path-creation-form/PathCreationForm';

import PathType from '../../consts/pathmodule/PathType';

import { PathSynchroRoute, PathReportingRoute } from '../../app-routes';

import './DashboardEmpty.css';

const DashboardEmpty: React.FC = () => {

    var navigate = useNavigate();

    const pathCreationFormHandleRef = useRef<PathCreationFormHandle>(null);

    function createPath(): void {
        if (pathCreationFormHandleRef.current !== null) {
            pathCreationFormHandleRef.current.Open();
        }
    };

    function watchTheTutorial(): void {
        window.open("https://pathta.gitbook.io/pathta-docs/", "_blank", "noreferrer");
    }

    function created(pathId: number, pathTypeId: number): void {
        switch (pathTypeId) {
            case PathType.DataSynchronization:
                navigate("/" + PathSynchroRoute + "/" + pathId);
                break;
            case PathType.Reporting:
                navigate("/" + PathReportingRoute + "/" + pathId);
                break;
            default:
        }
    };

    return (
        <div className={"Frame_15_2086_12567"}>
            <img src="svgs/dashboardemptyillustration.svg" alt="My Happy SVG" />
            <div className={"Frame_18_2088_1169"}>
                <div className={"Frame_16_2088_1170"}><span className={"Headline_2088_1171"}>You have not yet used Pathta</span><span className={"Subheadline_2088_1172"}>Use data without writing a single line of code, facilitate
                    integration and stimulate business innovation.</span></div>
                <div className={"Frame_1410103500_2088_1189"}>
                    <Button width={240} text="Contained" type="default" stylingMode="contained" onClick={createPath}>Create path</Button>
                    <Button width={240} text="outlined" type="normal" stylingMode="contained"><span className={"DecouvrirCetteOffre_2037_368"} onClick={watchTheTutorial}>Watch the tutorial</span></Button>
                </div>
            </div>
            <PathCreationForm ref={pathCreationFormHandleRef} Created={created} />
        </div>
    );
}

export default DashboardEmpty;