class UserListDto {
    Id: number;
    Email: string;
    FirstName: string;
    LastName: string;
    IsOwner: boolean;
    Status: number;
    StatusLabel: string;

    constructor(id: number, email: string, firstName: string, lastName: string, isOwner: boolean, status: number) {
        this.Id = id;
        this.Email = email;
        this.FirstName = firstName;
        this.LastName = lastName;
        this.IsOwner = isOwner;
        this.Status = status;
        this.StatusLabel = "";
        switch (status) {
            case 1: this.StatusLabel = "Invited"; break;
            case 2: this.StatusLabel = "Ok"; break;
            case 3: this.StatusLabel = "Revoked"; break;
        }
    }
}

export default UserListDto;
