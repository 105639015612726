class FieldTypeReferenceDto {
    Type: number;  // Assuming Type is a number representing some kind of field type identifier
    IsMultiple: boolean;
    TypeLabel: string;
    IsNullable: boolean;
    MaxLength: number | null;  // MaxLength can be null if there's no maximum length specified
    IsReadOnly: boolean;
    IsCalculated: boolean;
    IsBusinessRequired: boolean;
    OriginTypeLabel: string;
    LinkTableId: number | null;  // LinkTableId can be null if there's no linked table

    constructor(
        type: number,
        isMultiple: boolean,
        typeLabel: string,
        isNullable: boolean,
        maxLength: number | null,
        isReadOnly: boolean,
        isCalculated: boolean,
        isBusinessRequired: boolean,
        originTypeLabel: string,
        linkTableId: number | null
    ) {
        this.Type = type;
        this.IsMultiple = isMultiple;
        this.TypeLabel = typeLabel;
        this.IsNullable = isNullable;
        this.MaxLength = maxLength;
        this.IsReadOnly = isReadOnly;
        this.IsCalculated = isCalculated;
        this.IsBusinessRequired = isBusinessRequired;
        this.OriginTypeLabel = originTypeLabel;
        this.LinkTableId = linkTableId;
    }
}

export default FieldTypeReferenceDto;
