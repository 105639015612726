import React, { useState, useRef, useImperativeHandle, forwardRef, Fragment } from 'react';
import { DataGrid, Column, FilterRow, Scrolling, DataGridRef, Editing, Lookup, DataGridTypes } from 'devextreme-react/data-grid';
import { DataChange } from 'devextreme/common/grids';
import { SelectBox } from 'devextreme-react';
import CustomStore from 'devextreme/data/custom_store';
import GlobalLoader from '../loader/GlobalLoader';

import FieldType from '../../consts/datasourcemodule/FieldType';

import TargetFieldTypeCell from './TargetFieldTypeCell';

import { TransformationLogic } from '../../logics/transformationmodule/TransformationLogic';

import PathSynchroMappingCapabilitiesDto from '../../classes/dtos/pathsynchromodule/PathSynchroMappingCapabilitiesDto';

import FieldReferenceIdAndKeyDto from '../../classes/dtos/FieldReferenceIdAndKeyDto';
import FieldTypeReferenceDto from '../../classes/dtos/FieldTypeReferenceDto';
import PathSynchroMappingDto from '../../classes/dtos/pathsynchromodule/PathSynchroMappingDto';
import TransformationTypeDto from '../../classes/dtos/pathcommonmodule/TransformationTypeDto';
import TransformationCapabilitiesDto from '../../classes/dtos/transformationmodule/TransformationCapabilitiesDto';

import SynchroFieldMappingDto from '../../classes/dtos/pathsynchromodule/SynchroFieldMappingDto';

import './FieldGrid.css';
import './EditTransformationCell.css';

interface FieldGridProps {
    Mappings: PathSynchroMappingDto[] | null;
    MappingChanges: DataChange[] | undefined;

    SourceFields: FieldReferenceIdAndKeyDto[];
    TargetFields: FieldReferenceIdAndKeyDto[];
    Capabilities: PathSynchroMappingCapabilitiesDto[];
    TransformationsCapabilities: TransformationCapabilitiesDto[];
    Mapping: SynchroFieldMappingDto[];

    SetMappingChanges(changes: DataChange[], origin: SynchroFieldMappingDto[]): void;
}

export interface FieldGridHandle {
    Save: () => void;
}

const FieldGrid: React.FC<FieldGridProps> = forwardRef<FieldGridHandle, FieldGridProps>(({ Mappings, MappingChanges, SetMappingChanges, SourceFields, TargetFields, Capabilities, TransformationsCapabilities, Mapping }, ref) => {

    const dataGridRef = useRef<DataGridRef>(null);

    const [mapping] = useState<SynchroFieldMappingDto[]>(Mapping);

    type UpdateObject = { [key: string]: any };

    function updateObjectInPlace(target: UpdateObject, updates: UpdateObject): void {
        Object.assign(target, updates);
    }

    function getFields(targetFieldReferenceId: number, sourceFields: FieldReferenceIdAndKeyDto[], capabilities: PathSynchroMappingCapabilitiesDto[]): FieldReferenceIdAndKeyDto[] {
        let fields = new Array<FieldReferenceIdAndKeyDto>();
        sourceFields.forEach((sourceField) => {
            var currentCapabilities = capabilities.find((e) => e.TargetFieldId === targetFieldReferenceId) ?? null;
            if (currentCapabilities !== null) {
                var source = currentCapabilities.Capabilities.find((e) => e.SourceFieldId === sourceField.Id) ?? null;
                if (source !== null && source.TransformationLogicIds.length > 0) {
                    fields.push(sourceField);
                }
            }
        });
        return fields;
    }

    useImperativeHandle(ref, () => ({
        Save() {
            if (dataGridRef.current) {
                dataGridRef.current.instance().saveEditData();
            }
        }
    }));

    // Create a custom data source using CustomStore
    const customDataSource = new CustomStore({
        key: 'TargetFieldReferenceId',
        load: () => {
            return mapping || [];
        },
        update: (key: any, values: SynchroFieldMappingDto): any => {

            let row = mapping.find(e => e.TargetFieldReferenceId === key) ?? null;

            if (row !== null) {
                updateObjectInPlace(row, values);
            }
        },
        onLoaded: (): any => {
            if (dataGridRef.current?.instance() !== undefined) {
                dataGridRef.current.instance().option('editing.changes', MappingChanges);
            }
        }
    });

    if (!SourceFields || !TargetFields || !Capabilities) {
        return <GlobalLoader />
    }

    const calculateLookupFields = (rowData: SynchroFieldMappingDto) => {
        return getFields(rowData.TargetFieldReferenceId, SourceFields, Capabilities);
    };

    const calculateLookupTransformationTypes = (rowData: SynchroFieldMappingDto) => {

        let transformations = new Array<TransformationTypeDto>();

        if (rowData.SourceFieldReferenceId !== null) {
            var currentCapabilities = Capabilities.find((e) => e.TargetFieldId === rowData.TargetFieldReferenceId) ?? null;
            if (currentCapabilities !== null) {
                var source = currentCapabilities.Capabilities.find((e) => e.SourceFieldId === rowData.SourceFieldReferenceId) ?? null;
                if (source !== null && source.TransformationLogicIds.length > 0) {
                    TransformationsCapabilities.forEach((transformationCapabilities) => {
                        var found = source?.TransformationLogicIds.find((x) => x === transformationCapabilities.LogicId) ?? null;
                        if (found !== null) {
                            transformations.push(new TransformationTypeDto(transformationCapabilities.LogicId, transformationCapabilities.Label));
                        }
                    });
                }
            }
        }

        return transformations;
    };

    const handleOnEditorPreparing = (e: any) => {
        if (e.parentType === 'dataRow' && e.dataField === 'SourceFieldReferenceId') {
            e.editorOptions.dataSource = calculateLookupFields(e.row.data);

            e.editorOptions.onValueChanged = (args: any) => {

                const rowIndex = e.row.rowIndex;

                if (dataGridRef.current !== null) {

                    let current = dataGridRef.current.instance();

                    // Transformation cleaning on type change
                    if (args.previousValue > 0) {
                        let previousfield = SourceFields.find((e: FieldReferenceIdAndKeyDto) => e.Id === args.previousValue) ?? null;
                        if (previousfield !== null) {
                            let newfield = SourceFields.find((e: FieldReferenceIdAndKeyDto) => e.Id === args.value) ?? null;
                            if (newfield !== null) {
                                if (previousfield.Type.Type !== newfield.Type.Type) {
                                    current.cellValue(rowIndex, 'TransformationType', null);
                                    current.cellValue(rowIndex, 'TransformationParameters', "");
                                }
                            }
                        }
                    }

                    e.setValue(args.value);
                    const nextColumnIndex = 3;
                    current.focus(current.getCellElement(rowIndex, nextColumnIndex));
                    current.repaintRows(rowIndex);
                    current.editCell(rowIndex, nextColumnIndex);
                }
            };
        }

        if (e.parentType === 'dataRow' && e.dataField === 'TransformationType') {
            e.editorOptions.dataSource = calculateLookupTransformationTypes(e.row.data);
        }
    };

    const handleSourceFieldReferenceIdCalculateDisplayValue = (e: any) => {
        let found = SourceFields.find((x) => x.Id === e.SourceFieldReferenceId) ?? null;
        return found?.Name ?? "";
    }

    const handleTransformationTypeCalculateDisplayValue = (e: any) => {
        let found = TransformationsCapabilities.find((x) => x.LogicId === e.TransformationType) ?? null;
        return found?.Label ?? "";
    }

    const handleOnChangesChange = (e: DataChange[]): void => {
        SetMappingChanges(e, mapping);
    }

    const getTagColor = (type: FieldTypeReferenceDto): string => {
        switch (type.Type) {
            case 0:
                return '#5A5A65';
            case FieldType.String:
                return '#00BBEE';
            case 8:
                return '#9A4AFF';
            case 3000:
                return '#0F7DFF';
        }
        return '#9A4AFF';
    }

    const getTextColor = (type: FieldTypeReferenceDto): string => {
        switch (type.Type) {
            case 0:
                return '#9393A2';
            case FieldType.String:
                return 'white';
            case 8:
                return 'white';
            case 3000:
                return 'white';

        }
        return 'white';
    }

    const SourceFieldTypeCell = (cellData: DataGridTypes.ColumnCellTemplateData) => {
        let synchroFieldMappingDto = cellData.data as SynchroFieldMappingDto;

        let sourceField = SourceFields.find((e) => e.Id === synchroFieldMappingDto.SourceFieldReferenceId) ?? null;

        if (sourceField !== null) {

            let fieldTypeReferenceDto = sourceField.Type
            let tagColor = getTagColor(fieldTypeReferenceDto);
            let textColor = getTextColor(fieldTypeReferenceDto);

            return (
                <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                    <div style={{ width: 'auto', height: 22, paddingLeft: 10, paddingRight: 10, paddingTop: 2, paddingBottom: 2, background: tagColor, borderRadius: 16, justifyContent: 'center', alignItems: 'center', gap: 6, display: 'inline-flex' }}>
                        <div style={{ textAlign: 'center', color: textColor, fontSize: 10, fontFamily: 'Manrope', fontWeight: '500', textTransform: 'uppercase', letterSpacing: 1, wordWrap: 'break-word' }}>{fieldTypeReferenceDto.TypeLabel}</div>
                    </div>
                    {(fieldTypeReferenceDto.IsBusinessRequired || !fieldTypeReferenceDto.IsNullable) && (
                        <img width={22} height={22} style={{ padding: 0, margin: 0 }} src="svgs/mandatory.svg" alt="mandatory" />
                    )}
                </div>
            )
        }
        else {
            return (
                <div></div>
            )
        }
    };

    const selectFieldCell = (cellData: DataGridTypes.ColumnCellTemplateData) => {
        if (cellData.value > 0) { return (<div>{cellData.displayValue}</div>); }
        return (<span>{cellData.value || <span style={{ color: "#aaa" }}>Select a field...</span>}</span>);
    };

    const ShowTransformationCell = (cellData: DataGridTypes.ColumnCellTemplateData) => {
        let options = TransformationLogic.GetOptionsDetails(cellData.data, SourceFields, TargetFields, TransformationsCapabilities);
        if (options) {

            let iconType = 1; // Date
            if (options.Options.length !== 12) {
                iconType = 2;
            }

            try {
                var data = JSON.parse(cellData.data.TransformationParameters);
                if (data) {
                    if (data.Id) {
                        var label = options.Options.find((e) => e.Id === data.Id)?.Label ?? "";
                        return (
                            <div className="date-format-container-show">
                                <label className="date-format-label">
                                    {iconType === 1 ? (<span className="icon-calendar">&#128197;</span>) : ("")}
                                    {iconType === 2 ? (<span className="icon-calendar">&#128291;</span>) : ("")}
                                </label>
                                <span>{label}</span>
                            </div>
                        );
                    }
                }
            }
            catch (e) {
                console.log("ERROR " + e + " " + cellData.data.TransformationParameters);
            }
        }

        return (
            <Fragment></Fragment>
        );
    };

    const EditTransformationCell = (cellData: DataGridTypes.ColumnCellTemplateData) => {

        let options = TransformationLogic.GetOptionsDetails(cellData.data, SourceFields, TargetFields, TransformationsCapabilities);
        if (options) {

            let iconType = 1; // Date
            if (options.Options.length !== 12) {
                iconType = 2;
            }

            return (
                <div className="date-format-container-edit">
                    <label className="date-format-label">
                        {iconType === 1 ? (<span className="icon-calendar">&#128197;</span>) : ("")}
                        {iconType === 2 ? (<span className="icon-calendar">&#128291;</span>) : ("")}
                    </label>
                    <SelectBox dataSource={options.Options} displayExpr="Label" onSelectionChanged={(e) => cellData.setValue("{ \"Id\" : " + e.selectedItem.Id + " }")}></SelectBox>
                </div>
            );
        }

        return (<div></div>);
    };

    const renderUnlinkCommand = (cellData: DataGridTypes.ColumnCellTemplateData) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
                {(cellData.data.SourceFieldReferenceId > 0) ?
                    <img width={22} height={22} style={{ padding: '0px', cursor: 'pointer' }} src="svgs/unlink.svg" alt="Unlink" onClick={() => UnlinkField(cellData)} /> :
                    <img width={22} height={22} style={{ padding: '0px' }} src="svgs/link.svg" alt="link" />}
            </div>
        );
    }

    const UnlinkField = (cellData: DataGridTypes.ColumnCellTemplateData) => {
        if (dataGridRef.current) {
            let rowIndex = cellData.row.rowIndex;
            const dataGrid = dataGridRef.current.instance();
            dataGrid.cellValue(rowIndex, 'IsKey', false);
            dataGrid.cellValue(rowIndex, 'SourceFieldReferenceId', null);
            dataGrid.cellValue(rowIndex, 'TransformationType', null);
            dataGrid.cellValue(rowIndex, 'TransformationParameters', "");
        }
    }

    return (
        <div className={"BlockCard-FieldGrid"}>
            <DataGrid
                id="dataGrid"
                ref={dataGridRef}
                dataSource={customDataSource}
                columnAutoWidth={false}
                height='calc(100vh - 340px)'
                onEditorPreparing={handleOnEditorPreparing}
                onEditingStart={(e) => {
                    e.cancel = e.data.ReadOnly;
                }}
                onRowPrepared={(e) => {
                    if (e.rowType === "data" && e.data.ReadOnly) {
                        e.rowElement.style.opacity = 0.5;
                    }
                }}
            >
                <Scrolling mode="infinite" />
                <FilterRow visible={true} />

                <Editing
                    mode="batch"
                    allowUpdating={true}
                    allowAdding={false}
                    allowDeleting={false}
                    refreshMode="repaint"
                    onChangesChange={handleOnChangesChange}
                />

                <Column width={70} cellRender={renderUnlinkCommand} allowEditing={false} />
                <Column caption="SOURCE" cssClass="GridGroupHeader">
                    <Column caption="FIELD NAME" dataField="SourceFieldReferenceId" dataType="number" allowEditing={true} calculateDisplayValue={handleSourceFieldReferenceIdCalculateDisplayValue} cellRender={selectFieldCell} editorOptions={{ placeholder: "Select a field..." }}>
                        <Lookup
                            displayExpr="Name"
                            valueExpr="Id"
                        />
                    </Column>
                    <Column caption="FIELD TYPE" dataField="SourceFieldTypeLabel" dataType="string" allowEditing={false}
                        cellRender={SourceFieldTypeCell}
                        filterOperations={["contains"]}
                    />
                </Column>
                <Column caption="TRANSFORMATION" cssClass="GridGroupHeader">
                    <Column caption="TYPE" dataField="TransformationType" dataType="number" allowEditing={true} calculateDisplayValue={handleTransformationTypeCalculateDisplayValue}>
                        <Lookup
                            displayExpr="Label"
                            valueExpr="Id"
                        />
                    </Column>
                    <Column caption="TRANSFORMATION" dataField="TransformationParameters" dataType="string" allowEditing={true} cellRender={ShowTransformationCell} editCellRender={EditTransformationCell} />
                </Column>
                <Column caption="TARGET" cssClass="GridGroupHeader">
                    <Column caption="FIELD NAME" dataField="Name" dataType="string" allowEditing={false} />
                    <Column caption="FIELD TYPE" dataField="TypeLabel" dataType="string" allowEditing={false}
                        cellRender={TargetFieldTypeCell}
                        filterOperations={["contains"]}
                    />
                </Column>
                <Column caption="KEY" dataField="IsKey" dataType="boolean" allowEditing={true} width="100px" cssClass="GridGroupHeader" />
            </DataGrid>
        </div>
    );
});

export default FieldGrid;
