import React from 'react';

import TableMappingReadDto from '../../classes/dtos/pathcommonmodule/TableMappingReadDto';
import PathReportingMappingDto from '../../classes/dtos/pathreportingmodule/PathReportingMappingDto';

import FieldGrid from './FieldGrid';

import './ReportingExposedFields.css';

interface ReportingExposedFieldsProps {
    Source: TableMappingReadDto | null;
    Mappings: PathReportingMappingDto[];
    SetMappings(value: PathReportingMappingDto[]): void;
}

const ReportingExposedFields: React.FC<ReportingExposedFieldsProps> = ({ Source, Mappings, SetMappings }) => {

    return (
        <React.Fragment>
            <div style={{ width: '100%', height: '100%', position: 'relative', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
                <div style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
                    <FieldGrid DataSourceId={Source?.DataSourceId ?? 0} TableReferenceId={Source?.TableReferenceId ?? 0} Mappings={Mappings} SetMappings={SetMappings} />
                </div>
            </div>
        </React.Fragment>
    );
}

export default ReportingExposedFields;