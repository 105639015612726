import React, { useState, useEffect, useCallback, useRef } from 'react';
import { DataGrid, Selection, Column, FilterRow, Scrolling, DataGridRef, DataGridTypes } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import GlobalLoader from '../loader/GlobalLoader';

import FieldTypeCell from './FieldTypeCell';

import FieldReferenceLogic from '../../logics/datastructuremodule/FieldReferenceLogic';

import FieldReferenceIdAndKeyDto from '../../classes/dtos/FieldReferenceIdAndKeyDto';
import PathReportingMappingDto from '../../classes/dtos/pathreportingmodule/PathReportingMappingDto';

import ReportingFieldMappingDto from '../../classes/dtos/pathreportingmodule/ReportingFieldMappingDto';

import './FieldGrid.css';

interface FieldGridProps {
    DataSourceId: number;
    TableReferenceId: number;
    Mappings: PathReportingMappingDto[] | null;
    SetMappings(value: PathReportingMappingDto[]): void;
}

// Define the event type for selection change
//interface SelectionChangedEvent {
//    selectedRowsData: PathListDto[];
//}

const FieldGrid: React.FC<FieldGridProps> = ({ DataSourceId, TableReferenceId, Mappings, SetMappings }) => {

    const dataGridRef = useRef<DataGridRef>(null);

    const [fields, setFields] = useState<FieldReferenceIdAndKeyDto[]>([]);
    const [mapping, setMapping] = useState<ReportingFieldMappingDto[]>([]);

    const fieldsLoad = useCallback(async () => {

        if (DataSourceId > 0 && TableReferenceId > 0) {
            FieldReferenceLogic.GetFieldReferencesAsync(DataSourceId, TableReferenceId, true)
                .then(getFieldReferencesResult => {
                    var allFields = getFieldReferencesResult.Result.Fields
                        .toSorted((a, b) => (a.Name.toLowerCase() > b.Name.toLowerCase()) ? 1 : (a.Name.toLowerCase() < b.Name.toLowerCase()) ? -1 : 0);
                    setFields(allFields);
                    setMapping(allFields.map((e) => new ReportingFieldMappingDto(e.Id, false, e.Name, e.Type)));
                });
        }
    }, [DataSourceId, TableReferenceId]);

    useEffect(() => {
        fieldsLoad()
    }, [fieldsLoad]);
    
    useEffect(() => {
        if (Mappings !== null) {
            var selected = Mappings.map((e: PathReportingMappingDto) => e.FieldReferenceId);
            if (dataGridRef.current !== null) {
                dataGridRef.current.instance().selectRows(selected, false);
                dataGridRef.current.instance().refresh();
            }
        }
    }, [mapping, Mappings]);

    // Callback function when selection changes
    const onSelectionChanged = useCallback(
        ({ selectedRowsData }: DataGridTypes.SelectionChangedEvent) => {
            SetMappings(selectedRowsData.map((e: ReportingFieldMappingDto) => new PathReportingMappingDto(e.SourceFieldReferenceId, false, false, null, true)));
        }, [SetMappings]);

    // Create a custom data source using CustomStore
    const customDataSource = new CustomStore({
        key: 'SourceFieldReferenceId',
        load: () => {
            return mapping || [];
        },
    });

    if (!fields) {
        return <GlobalLoader />
    }

    return (
        <div className={"BlockCard-FieldGrid"}>
            <DataGrid
                id="dataGrid"
                ref={dataGridRef}
                dataSource={customDataSource}
                columnAutoWidth={false}
                onSelectionChanged={onSelectionChanged}
                onEditingStart={(e) => {
                    e.cancel = e.data.Type.Type === 0;
                }}
                onRowPrepared={(e) => {
                    if (e.rowType === "data" && e.data.Type.Type === 0) {
                        e.rowElement.style.pointerEvents = "none";
                        e.rowElement.style.cursor = "default";
                        e.rowElement.style.opacity = 0.5;
                    }
                }}
                height='calc(100vh - 340px)'
            >
                <Scrolling mode="infinite" />
                <Selection mode="multiple" selectAllMode="page" />
                <FilterRow visible={true} />
                <Column caption="NAME" dataField="Name" dataType="string" />
                <Column caption="FIELD TYPE" dataField="Type" dataType="object"
                    cellRender={FieldTypeCell}
                    calculateCellValue={(rowData: ReportingFieldMappingDto) => rowData.Type.TypeLabel}
                    calculateSortValue={(rowData: ReportingFieldMappingDto) => rowData.Type.TypeLabel}
                    filterOperations={["contains"]}
                />
            </DataGrid>
        </div>
    );
};

export default FieldGrid;
