import React, { useCallback, useState, useEffect, useMemo, useRef, forwardRef, useImperativeHandle } from 'react';
import { ValidationRule } from 'devextreme-react/common';
import { Popup } from 'devextreme-react/popup';
import { Form, Label, FormRef, GroupItem, SimpleItem, ButtonItem, EmptyItem } from 'devextreme-react/form';
import { ButtonType } from 'devextreme-react/common';
import 'devextreme-react/text-area';

import { useAuth } from '../../contexts/auth';

import DataSourceForm, { DataSourceFormHandle } from '../data-source-form/DataSourceForm';

import LabelTemplate from '../label-template/LabelTemplate';

import DataSourceApi from "../../api/DataSourceApi";

import DataSourceListDto from '../../classes/dtos/datasourcemodule/DataSourceListDto';

import DataSourceSelectionDto from '../../classes/dtos/datasourcemodule/DataSourceSelectionDto';

import ActionType from '../../classes/consts/ActionType';

import './DataSourceSelectionForm.scss';

interface DataSourceSelectionFormProps {
    Direction: string;
    SetDataSourceSelected(value: DataSourceSelectionDto): void;
}
export interface DataSourceSelectionFormHandle {
    Open: (dataSourceId: number) => void;
}

const DataSourceSelectionForm: React.FC<DataSourceSelectionFormProps> = forwardRef<DataSourceSelectionFormHandle, DataSourceSelectionFormProps>(({ Direction, SetDataSourceSelected }, ref) => {

    const dataSourceFormHandleRef = useRef<DataSourceFormHandle>(null);

    const [dataSourceSelectionDto, setDataSourceSelectionDto] = useState<DataSourceSelectionDto>(new DataSourceSelectionDto(null));

    const [dataSourceId, setDataSourceId] = useState<number>(-1);

    const [isOpen, setIsOpen] = useState(false);

    useImperativeHandle(ref, () => ({
        Open(dataSourceId: number) {
            if (dataSources !== null) {
                let current = dataSources.find((e) => e.Id === dataSourceId) ?? null;
                setDataSourceSelectionDto(new DataSourceSelectionDto(current));
            }
            setIsOpen(true);
        }
    }));

    function editDataSource(): void {
        if (dataSources !== null) {
            let current = dataSources.find((e) => e.Id === dataSourceSelectionDto.DataSource?.Id) ?? null;
            if (current !== null) {
                if (dataSourceFormHandleRef.current !== null) {
                    dataSourceFormHandleRef.current.Open(current.Id);
                }
            }
        }
    };

    function createDataSource(): void {
        if (dataSourceFormHandleRef.current !== null) {
            dataSourceFormHandleRef.current.Open(null);
        }
    };

    function cancel(): void {
        setIsOpen(false);
    };

    const formRef = useRef<FormRef>(null);

    const [dataSources, setDataSources] = useState<DataSourceListDto[] | null>(null);

    const [currentUserId] = useState<number>(useAuth().user?.UserId ?? 0);

    const [currentDataSource, setCurrentDataSource] = useState(null);

    const dataSourceEditorOptions = {
        items: dataSources,
        searchEnabled: true,
        displayExpr: "Name",
        onValueChanged: (e) => {
            setCurrentDataSource(e.value);
        },
        buttons: [{
            name: 'edit',
            location: 'after',
            options: {
                stylingMode: 'text',
                icon: 'edit',
                onClick: () => {
                    editDataSource();
                },
                disabled: currentDataSource?.CreatedById !== currentUserId
            },
        }]
    };

    const dataSourcesLoad = useCallback(async () => {
        if (!dataSources) {
            var readable = Direction === "Source" ? true : null;
            var writable = Direction === "Target" ? true : null;

            DataSourceApi.GetDataSourcesByCustomerAsync(readable, writable)
                .then(getDataSourcesResult => {
                    var allDataSources = getDataSourcesResult.Result!.DataSources
                        .toSorted((a, b) => (a.Name.toLowerCase() > b.Name.toLowerCase()) ? 1 : (a.Name.toLowerCase() < b.Name.toLowerCase()) ? -1 : 0);

                    setDataSources(allDataSources);

                    let selected = allDataSources.find((e) => e.Id === dataSourceId) ?? null;

                    if (selected !== null) {
                        setDataSourceSelectionDto((previous) => { return new DataSourceSelectionDto(selected); });
                    }
                });
        }

    }, [Direction, dataSources, dataSourceId]);

    useEffect(() => {
        dataSourcesLoad()
    }, [dataSourcesLoad]);

    const validationRules: {
        dataSource: ValidationRule[]
    } = {
        dataSource: [
            { type: 'required', message: 'Data source is required.' },
        ]
    };

    const popupAttributes = useMemo(() => {
        return {
            id: 'elementId',
            class: 'class-name-popup'
        }
    }, []);

    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        SetDataSourceSelected(dataSourceSelectionDto);
        setIsOpen(false);
    };

    function handleDataSourceAdded(dataSourceId: number) {
        setDataSources(null);
        setDataSourceId(dataSourceId);
    }

    const createSourceButtonOptions = {
        text: 'Create a new source',
        type: 'outlined' as ButtonType,
        useSubmitBehavior: false,
        width: '100%',
        icon: 'plus',
        onClick: createDataSource
    };

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'outlined' as ButtonType,
        useSubmitBehavior: false,
        width: '100%',
        onClick: cancel
    };

    const confirmButtonOptions = {
        text: 'Confirm',
        type: 'default' as ButtonType,
        useSubmitBehavior: true,
        width: '100%'
    };

    return (
        <React.Fragment>
            <Popup
                wrapperAttr={popupAttributes}
                visible={isOpen}
                onHiding={cancel}
                dragEnabled={false}
                hideOnOutsideClick={false}
                showCloseButton={true}
                showTitle={true}
                title="Choose your source"
                container=".dx-viewport"
                width={600}
                height="auto"
            >
                <form onSubmit={handleSubmit}>
                    <Form
                        ref={formRef}
                        formData={dataSourceSelectionDto}
                        showColonAfterLabel={true}
                        showValidationSummary={false}
                        validationGroup="dataSourceSelectionDtoData"
                        colCount={2}
                    >
                        <GroupItem colSpan={2}>
                            <SimpleItem dataField="DataSource" editorType="dxSelectBox" editorOptions={dataSourceEditorOptions} validationRules={validationRules.dataSource}>
                                <Label render={LabelTemplate('product')} />
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem colSpan={2}>
                            <div style={{ width: '100%', height: 36, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 12, display: 'inline-flex' }}>
                                <div style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 4, display: 'inline-flex' }}>
                                    <div style={{ width: 20, height: 0, opacity: 0.50, border: '1px #5A5A65 solid' }}></div>
                                    <div style={{ color: '#9393A2', fontSize: 10, fontFamily: 'Manrope', fontWeight: '600', textTransform: 'uppercase', lineHeight: '13px', letterSpacing: 1, wordWrap: 'break-word' }}>OR</div>
                                    <div style={{ width: 20, height: 0, opacity: 0.50, border: '1px #5A5A65 solid' }}></div>
                                </div>
                            </div>
                        </GroupItem>
                        <GroupItem colSpan={2}>
                            <ButtonItem buttonOptions={createSourceButtonOptions} />
                        </GroupItem>
                        <EmptyItem colSpan={2}/>
                        <GroupItem>
                            <ButtonItem buttonOptions={cancelButtonOptions} />
                        </GroupItem>
                        <GroupItem>
                            <ButtonItem buttonOptions={confirmButtonOptions} />
                        </GroupItem>
                    </Form>
                </form>
            </Popup>
            <DataSourceForm ref={dataSourceFormHandleRef} Action={ActionType.Create} Direction={Direction} DataSourceAdded={handleDataSourceAdded} />
        </React.Fragment>
    );
});

export default DataSourceSelectionForm;