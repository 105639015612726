import React, { useState, useEffect, useCallback } from 'react';

import { ScrollView } from 'devextreme-react';

import TokenBox from './TokenBox';

import ConstructionInProgressForm from '../construction-in-progress-form/ConstructionInProgressForm';

import FieldReferenceLogic from '../../logics/datastructuremodule/FieldReferenceLogic';
import PathReportingApi from '../../api/PathReportingApi';

import TableMappingReadDto from '../../classes/dtos/pathcommonmodule/TableMappingReadDto';
import PathReportingMappingDto from '../../classes/dtos/pathreportingmodule/PathReportingMappingDto';

import ReportingFieldMappingDto from '../../classes/dtos/pathreportingmodule/ReportingFieldMappingDto';

import './ReportingResume.css';

interface ReportingResumeProps {
    Source: TableMappingReadDto | null;
    Mappings: PathReportingMappingDto[];
}

const ReportingResume: React.FC<ReportingResumeProps> = ({ Source, Mappings }) => {

    const [underConstructionIsOpen, setUnderConstructionIsOpen] = useState<boolean>(false);

    const [fields, setFields] = useState<ReportingFieldMappingDto[]>([]);

    const fieldsLoad = useCallback(async () => {

        if (Source !== null && Source.DataSourceId > 0 && Source.TableReferenceId > 0) {
            FieldReferenceLogic.GetFieldReferencesAsync(Source.DataSourceId, Source.TableReferenceId, false)
                .then(getFieldReferencesResult => {
                    var allFields = getFieldReferencesResult.Result.Fields
                        .toSorted((a, b) => (a.Name.toLowerCase() > b.Name.toLowerCase()) ? 1 : (a.Name.toLowerCase() < b.Name.toLowerCase()) ? -1 : 0);

                    var allExposedFields = new Array<ReportingFieldMappingDto>();
                    allFields.forEach((field) => {
                        var index = Mappings.findIndex(x => x.FieldReferenceId === field.Id);
                        if (index !== -1) {
                            allExposedFields.push(new ReportingFieldMappingDto(field.Id, true, field.Name, field.Type));
                        }
                    });

                    setFields(allExposedFields);
                });
        }
    }, [Source, Mappings]);

    useEffect(() => {
        fieldsLoad()
    }, [fieldsLoad]);

    const [token, setToken] = useState<string>("");

    const tokenLoad = useCallback(async () => {

        PathReportingApi.EnsurePersonalAccessTokenAsync()
            .then(ensurePersonalAccessTokenResult => {
                if (ensurePersonalAccessTokenResult.IsFailed()) {
                    alert(ensurePersonalAccessTokenResult.ErrorMessage);
                }

                setToken(ensurePersonalAccessTokenResult.Result.PersonalAccessToken);
            });
    }, [setToken]);

    useEffect(() => {
        tokenLoad()
    }, [tokenLoad]);

    return (
        <React.Fragment>
            <ScrollView style={{ width: '100%', height: 'calc(100vh - 320px)' }}>
                <div style={{ width: '100%', minHeight: 'calc(100vh - 320px)', height: 'auto', padding: '20px', position: 'relative', display:'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ width: '100%', height: 'auto', position: 'relative' }} >
                        <div style={{ width: '100%', height: 'auto', position: 'relative', justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
                            <div style={{ width: 'auto', height: 'auto', position: 'relative', justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
                                <div style={{ paddingTop: 12, paddingBottom: 16, paddingLeft: 16, paddingRight: 16, background: '#1A1A20', borderRadius: 16, border: '1px #3D3D46 solid', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 16, display: 'inline-flex' }}>
                                    <div style={{ alignSelf: 'stretch', paddingLeft: 10, paddingRight: 10, paddingTop: 6, paddingBottom: 6, justifyContent: 'center', alignItems: 'center', gap: 10, display: 'inline-flex' }}>
                                        <div style={{ color: '#E3E3E8', fontSize: 12, fontFamily: 'Manrope', fontWeight: '600', lineHeight: '15.60px', letterSpacing: 0.24, wordWrap: 'break-word' }}>SOURCE</div>
                                    </div>
                                    <div style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                        <div style={{ alignSelf: 'stretch', height: 79, padding: 16, background: '#202028', borderRadius: 10, border: '1px #3D3D46 solid', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: 10, display: 'flex' }}>
                                            <div style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: 10, display: 'flex' }}>
                                                <div style={{ color: '#9393A2', fontSize: 10, fontFamily: 'Manrope', fontWeight: '500', textTransform: 'uppercase', lineHeight: '13px', letterSpacing: 1, wordWrap: 'break-word' }}>Selected source</div>
                                                <div style={{ color: '#E3E3E8', fontSize: 16, fontFamily: 'Manrope', fontWeight: '500', lineHeight: '24px', letterSpacing: 0.32, wordWrap: 'break-word' }}>{Source?.DataSourceLabel ?? ""}</div>
                                            </div>
                                        </div>
                                        <div style={{ width: 1, height: 32, transformOrigin: '0 0', border: '1px #3D3D46 dotted' }}></div>
                                        <div style={{ alignSelf: 'stretch', height: 79, padding: 16, background: '#202028', borderRadius: 10, border: '1px #3D3D46 solid', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: 10, display: 'flex' }}>
                                            <div style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: 10, display: 'flex' }}>
                                                <div style={{ color: '#9393A2', fontSize: 10, fontFamily: 'Manrope', fontWeight: '500', textTransform: 'uppercase', lineHeight: '13px', letterSpacing: 1, wordWrap: 'break-word' }}>Selected DATABASE</div>
                                                <div style={{ color: '#E3E3E8', fontSize: 16, fontFamily: 'Manrope', fontWeight: '500', lineHeight: '24px', letterSpacing: 0.32, wordWrap: 'break-word' }}>{Source?.DatabaseReferenceLabel ?? ""}</div>
                                            </div>
                                        </div>
                                        <div style={{ width: 1, height: 32, transformOrigin: '0 0', border: '1px #3D3D46 dotted' }}></div>
                                        <div style={{ alignSelf: 'stretch', height: 79, padding: 16, background: '#202028', borderRadius: 10, border: '1px #3D3D46 solid', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: 10, display: 'flex' }}>
                                            <div style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: 10, display: 'flex' }}>
                                                <div style={{ color: '#9393A2', fontSize: 10, fontFamily: 'Manrope', fontWeight: '500', textTransform: 'uppercase', lineHeight: '13px', letterSpacing: 1, wordWrap: 'break-word' }}>Selected TABLE</div>
                                                <div style={{ color: '#E3E3E8', fontSize: 16, fontFamily: 'Manrope', fontWeight: '500', lineHeight: '24px', letterSpacing: 0.32, wordWrap: 'break-word' }}>{Source?.TableReferenceLabel ?? ""}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 4, display: 'flex' }}>
                                    <div style={{ width: 20, height: 0, opacity: 0.50, border: '1px #5A5A65 solid' }}></div>
                                    <div style={{ color: '#9393A2', fontSize: 10, fontFamily: 'Manrope', fontWeight: '600', textTransform: 'uppercase', lineHeight: '13px', letterSpacing: 1, wordWrap: 'break-word' }}>fields</div>
                                    <div style={{ width: 20, height: 0, opacity: 0.50, border: '1px #5A5A65 solid' }}></div>
                                </div>
                                <div style={{ paddingTop: 12, paddingBottom: 16, paddingLeft: 16, paddingRight: 16, background: '#1A1A20', borderRadius: 16, border: '1px #3D3D46 solid', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 16, display: 'inline-flex' }}>
                                    <div style={{ alignSelf: 'stretch', paddingLeft: 10, paddingRight: 10, paddingTop: 6, paddingBottom: 6, justifyContent: 'center', alignItems: 'center', gap: 10, display: 'inline-flex' }}>
                                        <div style={{ color: '#E3E3E8', fontSize: 12, fontFamily: 'Manrope', fontWeight: '600', lineHeight: '15.60px', letterSpacing: 0.24, wordWrap: 'break-word' }}>EXPOSED FIELDS</div>
                                    </div>
                                    <div style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                        <div style={{ padding: 16, background: '#202028', borderRadius: 10, border: '1px #3D3D46 solid', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: 10, display: 'flex' }}>
                                            <div style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: 10, display: 'flex' }}>
                                                <div style={{ color: '#9393A2', fontSize: 10, fontFamily: 'Manrope', fontWeight: '500', textTransform: 'uppercase', lineHeight: '13px', letterSpacing: 1, wordWrap: 'break-word' }}>select fields</div>
                                                <div style={{ flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 4, display: 'flex' }}>

                                                    {fields.map((field, i) => (
                                                        <div style={{ alignSelf: 'stretch', paddingLeft: 8, paddingRight: 8, paddingTop: 4, paddingBottom: 4, background: '#5A5A65', borderRadius: 4, justifyContent: 'center', alignItems: 'center', gap: 10, display: 'inline-flex', minWidth: 150 }}>
                                                            <div style={{ color: '#E3E3E8', fontSize: 14, fontFamily: 'Manrope', fontWeight: '500', lineHeight: '21px', letterSpacing: 0.28, wordWrap: 'break-word' }}>{field.Name}</div>
                                                        </div>)
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ width: '20px', height: '20px' }}></div>
                        <div style={{ width: '100%', position: 'relative', justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
                            <div style={{ height: 100, padding: 16, left: 0, top: 0, position: 'relative', background: '#1A1A20', borderRadius: 10, border: '1px #3D3D46 solid', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'inline-flex' }}>
                                <div style={{ alignSelf: 'stretch', justifyContent: 'space-between', alignItems: 'center', display: 'inline-flex' }}>
                                    <div style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 8, display: 'flex' }}>
                                        <img src="svgs/info.svg" alt="info"></img>
                                        <div style={{ flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 12, display: 'inline-flex' }}>
                                            <div style={{ color: '#E3E3E8', fontSize: 16, fontFamily: 'Manrope', fontWeight: '600', lineHeight: '24px', letterSpacing: 0.32, wordWrap: 'break-word' }}>To complete the configuration, please add the following token to your tool</div>
                                        </div>
                                        <TokenBox Token={token} />
                                    </div>
                                </div>
                                <div style={{ alignSelf: 'stretch', paddingLeft: 24, paddingRight: 24, justifyContent: 'flex-start', alignItems: 'center', gap: 4, display: 'inline-flex' }}>
                                    <div style={{ flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 12, display: 'inline-flex' }}>
                                        <div style={{ color: '#9393A2', fontSize: 14, fontFamily: 'Manrope', fontWeight: '500', lineHeight: '21px', letterSpacing: 0.28, wordWrap: 'break-word' }}>If you need help with this step, please see our</div>
                                    </div>
                                    <div style={{ alignSelf: 'stretch', justifyContent: 'center', alignItems: 'center', gap: 10, display: 'flex' }}>
                                        <div style={{ color: '#E3E3E8', fontSize: 14, fontFamily: 'Manrope', fontWeight: '500', lineHeight: '21px', letterSpacing: 0.28, wordWrap: 'break-word', cursor: 'pointer' }} onClick={() => setUnderConstructionIsOpen(true)}>video tutorial</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ScrollView>
            <ConstructionInProgressForm IsOpen={underConstructionIsOpen} SetIsOpen={setUnderConstructionIsOpen} />
        </React.Fragment>
    );
}

export default ReportingResume;