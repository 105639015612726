class DataSourceListDto {
    Id: number;
    Name: string;
    Type: string;
    ConnectorType: string;
    CreatedById: number;

    constructor(id: number, name: string, type: string, connectorType: string, createdById: number) {
        this.Id = id;
        this.Name = name;
        this.Type = type;
        this.ConnectorType = connectorType;
        this.CreatedById = createdById;
    }
}

export default DataSourceListDto;
