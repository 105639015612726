import { IJobResumeDto } from "./IJobResumeDto";

export default class JobRunDto {

    Id: number;
    Name: string;
    PathId: number;
    PathTypeId: number;
    PathTypeLabel: string;
    Start: Date;
    Status: JobStatusDto;
    Progress: number;
    Resume: IJobResumeDto | null;

    constructor(
        id: number,
        name: string,
        pathId: number,
        pathTypeId: number,
        pathTypeLabel: string,
        start: Date,
        status: JobStatusDto,
        progress: number,
        resume : IJobResumeDto | null
    ) {
        this.Id = id;
        this.Name = name;
        this.PathId = pathId;
        this.PathTypeId = pathTypeId;
        this.PathTypeLabel = pathTypeLabel;
        this.Start = new Date(start);
        this.Status = status;
        this.Progress = progress;
        this.Resume = resume;
    }
}
