import React, { useState, useEffect, useCallback, useRef } from 'react';
import { DataChange } from 'devextreme/common/grids';
import Tabs, { Item } from 'devextreme-react/tabs';
import { useNavigate, useParams } from 'react-router-dom';

import { Toast } from 'devextreme-react/toast';

import { Button, TextBox } from 'devextreme-react';

import MessageForm, { MessageFormHandle, WarningMessage } from '../../components/message-form/MessageForm';
import ChangeInProgressForm from '../../components/change-in-progress-form/ChangeInProgressForm';

import SourceSelection from '../../components/source-selection/SourceSelection';
import SynchroMappingFields, { SynchroMappingFieldsHandle } from '../../components/synchro-mapping-fields/SynchroMappingFields';
import SynchroSetup from '../../components/synchro-setup/SynchroSetup';
import SynchroResume from '../../components/synchro-resume/SynchroResume';

import PathSynchroApi from '../../api/PathSynchroApi';

import GetPathSynchroDetailResult from '../../classes/api/result/pathsynchromodule/GetPathSynchroDetailResult';
import PathSynchroMappingDto from '../../classes/dtos/pathsynchromodule/PathSynchroMappingDto';

import TableMappingReadDto from '../../classes/dtos/pathcommonmodule/TableMappingReadDto';
import SynchroFieldMappingDto from '../../classes/dtos/pathsynchromodule/SynchroFieldMappingDto';

import PathCheckResultDto from '../../classes/dtos/pathmodule/PathCheckResultDto';
import PathSynchroSetupDto from '../../classes/dtos/pathsynchromodule/PathSynchroSetupDto';
import ScheduleDto from '../../classes/dtos/schedulermodule/ScheduleDto';

import TransformationCapabilitiesDto from '../../classes/dtos/transformationmodule/TransformationCapabilitiesDto';
import TransformationNotConfigurableDto from '../../classes/dtos/transformationmodule/TransformationNotConfigurableDto';
import FieldReferenceIdAndKeyDto from '../../classes/dtos/FieldReferenceIdAndKeyDto';
import PathSynchroMappingCapabilitiesDto from '../../classes/dtos/pathsynchromodule/PathSynchroMappingCapabilitiesDto';

import './path-synchro.scss';

export default function PathSynchro() {

    const { id } = useParams();

    const navigate = useNavigate();

    const pathId = Number.parseInt(id ?? "0");

    const messageFormHandleRef = useRef<MessageFormHandle>(null);

    const synchroMappingFieldsRef = useRef<SynchroMappingFieldsHandle>(null);

    const [pathSynchroDetail, setPathSynchroDetail] = useState<GetPathSynchroDetailResult | null>(null);

    const [sourceFields, setSourceFields] = useState<FieldReferenceIdAndKeyDto[]>([]);
    const [targetFields, setTargetFields] = useState<FieldReferenceIdAndKeyDto[]>([]);
    const [capabilities, setCapabilities] = useState<PathSynchroMappingCapabilitiesDto[]>([]);
    const [transformationsCapabilities, setTransformationsCapabilities] = useState<TransformationCapabilitiesDto[]>([]);

    const [mapping, setMapping] = useState<SynchroFieldMappingDto[]>([]);

    const [currentTab, setCurrentTab] = useState<number>(0);

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const [changeInprogress, setChangeInprogress] = useState<boolean>(false);

    const [editNameInprogress, setEditNameInprogress] = useState<string>(null);

    const [isLaunchable, setIsLaunchable] = useState<boolean>(false);

    const dataChangesRef = useRef<DataChange[]>([]);

    const pathSynchroMappingDtosRef = useRef<PathSynchroMappingDto[]>([]);

    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        type: 'info',
        message: '',
        withRedirect: false
    } as {
        isVisible: boolean,
        type: 'info' | 'error' | 'success',
        message: string,
        withRedirect: boolean
    });

    useEffect(() => {
        PathSynchroApi.GetPathSynchroDetailAsync(pathId)
            .then(detail => {
                setPathSynchroDetail(detail.Result as GetPathSynchroDetailResult);
                setIsLaunchable((detail.Result as GetPathSynchroDetailResult).PublishedVersionId !== null)
            });
    }, [pathId]);

    function GetPathSynchroMappingCapabilities(targetFieldId: number, sourceFieldId: number) {
        var currentCapabilities = capabilities.find((e) => e.TargetFieldId === targetFieldId) ?? null;
        if (currentCapabilities !== null) {
            return currentCapabilities.Capabilities.find((e) => e.SourceFieldId === sourceFieldId) ?? null;
        }
        return null;
    }

    function GetTransformationCapabilitiesDto(logicId: number) {
        return transformationsCapabilities.find((e) => e.LogicId === logicId) ?? null;
    }

    function GetFieldName(targetFieldId: number) {
        return targetFields.find((e) => e.Id === targetFieldId)?.Name ?? "";
    }

    function getFields(targetFieldReferenceId: number, sourceFields: FieldReferenceIdAndKeyDto[], capabilities: PathSynchroMappingCapabilitiesDto[]): FieldReferenceIdAndKeyDto[] {
        let fields = new Array<FieldReferenceIdAndKeyDto>();
        sourceFields.forEach((sourceField) => {
            var currentCapabilities = capabilities.find((e) => e.TargetFieldId === targetFieldReferenceId) ?? null;
            if (currentCapabilities !== null) {
                var source = currentCapabilities.Capabilities.find((e) => e.SourceFieldId === sourceField.Id) ?? null;
                if (source !== null && source.TransformationLogicIds.length > 0) {
                    fields.push(sourceField);
                }
            }
        });
        return fields;
    }

    const dataLoad = useCallback(async () => {

        if (pathSynchroDetail === null) {
            return;
        }

        if (pathSynchroDetail.Source === null) {
            return;
        }

        if (pathSynchroDetail.Target === null) {
            return;
        }

        if (pathSynchroDetail.Source.DataSourceId > 0 && pathSynchroDetail.Source.TableReferenceId > 0 && pathSynchroDetail.Target.DataSourceId > 0 && pathSynchroDetail.Target.TableReferenceId > 0) {

            var getPathSynchroCapabilitiesResult = await PathSynchroApi.GetPathSynchroCapabilitiesAsync(pathSynchroDetail.Source.TableReferenceId, pathSynchroDetail.Target.TableReferenceId);

            if (getPathSynchroCapabilitiesResult.IsFailed()) {
                alert("Error : " + getPathSynchroCapabilitiesResult.ErrorMessage);
            }

            var allFieldsSource = getPathSynchroCapabilitiesResult.Result.SourceFields
                .toSorted((a, b) => (a.Name.toLowerCase() > b.Name.toLowerCase()) ? 1 : (a.Name.toLowerCase() < b.Name.toLowerCase()) ? -1 : 0);
            setSourceFields(allFieldsSource);

            var allFieldsTarget = getPathSynchroCapabilitiesResult.Result.TargetFields
                .toSorted((a, b) => (a.Name.toLowerCase() > b.Name.toLowerCase()) ? 1 : (a.Name.toLowerCase() < b.Name.toLowerCase()) ? -1 : 0);
            setTargetFields(allFieldsTarget);

            setCapabilities(getPathSynchroCapabilitiesResult.Result.MappingsCapabilities);
            var transformationsCapabilitiesTemp = getPathSynchroCapabilitiesResult.Result.TransformationsCapabilities;
            transformationsCapabilitiesTemp.push(new TransformationNotConfigurableDto(0, 0, 0, "Direct", 0, "TransformationNotConfigurable"));
            setTransformationsCapabilities(transformationsCapabilitiesTemp);

            let Mappings = pathSynchroDetail?.Mappings ?? [];

            let mappingTemp = allFieldsTarget.map((e) => {
                let readMapping = Mappings?.find(x => x.TargetFieldReferenceId === e.Id) ?? null;
                let readOnly = (e.Type.IsReadOnly || e.Type.Type === 0 || getFields(e.Id, allFieldsSource, getPathSynchroCapabilitiesResult.Result.MappingsCapabilities).length === 0);
                if (readMapping === null) {
                    return new SynchroFieldMappingDto(false, null, null, null, e.Id, e.Name, e.Type, readOnly);
                } else {
                    return new SynchroFieldMappingDto(readMapping.IsKey, readMapping.SourceFieldReferenceId, readMapping.TransformationLogicId, readMapping.TransformationParameters, e.Id, e.Name, e.Type, readOnly);
                }
            })

            setMapping(mappingTemp);

            let result = new Array<PathSynchroMappingDto>();

            mappingTemp.forEach((e: SynchroFieldMappingDto) => result.push(new PathSynchroMappingDto(e.IsKey, e.SourceFieldReferenceId ?? 0, e.TargetFieldReferenceId, e.TransformationType ?? 0, e.TransformationParameters ?? "")));

            pathSynchroMappingDtosRef.current = result.filter((e: PathSynchroMappingDto) => e.SourceFieldReferenceId !== null && e.SourceFieldReferenceId !== 0);

        }
    }, [pathSynchroDetail?.Source, pathSynchroDetail?.Target]);

    useEffect(() => {
        dataLoad()
    }, [dataLoad]);

    function handleNameChange() {
        setPathSynchroDetail((previous) => {
            if (previous === null) {
                return previous;
            }
            setChangeInprogress(true);
            previous.Name = editNameInprogress;
            setEditNameInprogress(null);
            return previous;
        });
    }

    const handleDataChanges = useCallback((changes: DataChange[], origin: SynchroFieldMappingDto[]) => {
        dataChangesRef.current = changes;

        let result = new Array<PathSynchroMappingDto>();

        origin.forEach((e: SynchroFieldMappingDto) => result.push(new PathSynchroMappingDto(e.IsKey, e.SourceFieldReferenceId ?? 0, e.TargetFieldReferenceId, e.TransformationType ?? 0, e.TransformationParameters ?? "")));

        changes.forEach((e: DataChange) => {

            let row = result.find((x) => x.TargetFieldReferenceId === e.key) ?? null;

            if (row !== null) {
                if (e.data.IsKey !== undefined) {
                    row.IsKey = e.data.IsKey;
                }
                if (e.data.SourceFieldReferenceId !== undefined) {
                    row.SourceFieldReferenceId = e.data.SourceFieldReferenceId;
                }
                if (e.data.TransformationType !== undefined) {
                    row.TransformationLogicId = e.data.TransformationType;
                }
                if (e.data.TransformationParameters !== undefined) {
                    row.TransformationParameters = e.data.TransformationParameters;
                }
            }
        });

        pathSynchroMappingDtosRef.current = result.filter((e: PathSynchroMappingDto) => e.SourceFieldReferenceId !== null && e.SourceFieldReferenceId !== 0);

    }, []);

    const handleTableMappingSourceChange = useCallback((tableMappingReadDto: TableMappingReadDto) => {
        setPathSynchroDetail((previous) => {
            if (previous === null) {
                return previous;
            }

            return new GetPathSynchroDetailResult(
                previous.Id,
                previous.Name,
                tableMappingReadDto,
                previous.Target,
                previous.Mappings,
                previous.Schedule,
                previous.Setup,
                previous.LastVersionId,
                previous.PublishedVersionId);
        });

    }, []);

    const handleTableMappingTargetChange = useCallback((tableMappingReadDto: TableMappingReadDto) => {
        setPathSynchroDetail((previous) => {
            if (previous === null) {
                return previous;
            }

            return new GetPathSynchroDetailResult(
                previous.Id,
                previous.Name,
                previous.Source,
                tableMappingReadDto,
                previous.Mappings,
                previous.Schedule,
                previous.Setup,
                previous.LastVersionId,
                previous.PublishedVersionId);
        });

    }, []);

    const handlePathSynchroSetupChange = useCallback((pathSynchroSetupDto: PathSynchroSetupDto) => {
        setPathSynchroDetail((previous) => {
            if (previous === null) {
                return previous;
            }

            return new GetPathSynchroDetailResult(
                previous.Id,
                previous.Name,
                previous.Source,
                previous.Target,
                previous.Mappings,
                previous.Schedule,
                pathSynchroSetupDto,
                previous.LastVersionId,
                previous.PublishedVersionId);
        });

    }, []);

    const handlePathSynchroScheduleChange = useCallback((pathSynchroScheduleDto: ScheduleDto) => {
        setPathSynchroDetail((previous) => {
            if (previous === null) {
                return previous;
            }

            return new GetPathSynchroDetailResult(
                previous.Id,
                previous.Name,
                previous.Source,
                previous.Target,
                previous.Mappings,
                pathSynchroScheduleDto,
                previous.Setup,
                previous.LastVersionId,
                previous.PublishedVersionId);
        });
    }, []);

    function CheckBeforeSaveAndPublish(): PathCheckResultDto {

        if (pathSynchroDetail === null) {
            return new PathCheckResultDto("No data (Technical issue)", PathCheckResultDto.BlockingForSave);
        }

        if (pathSynchroDetail.Name === "") {
            return new PathCheckResultDto("The name is mandatory", PathCheckResultDto.BlockingForSave);
        }

        if (pathSynchroDetail.Source?.TableReferenceId === null ||
            pathSynchroDetail.Source?.TableReferenceId === undefined ||
            pathSynchroDetail.Source?.TableReferenceId <= 0) {
            return new PathCheckResultDto("No source selected", PathCheckResultDto.BlockingForPublish);
        }

        if (pathSynchroDetail.Target?.TableReferenceId === null ||
            pathSynchroDetail.Target?.TableReferenceId === undefined ||
            pathSynchroDetail.Target?.TableReferenceId <= 0) {
            return new PathCheckResultDto("No target selected", PathCheckResultDto.BlockingForPublish);
        }

        if (pathSynchroMappingDtosRef.current.length === 0) {
            return new PathCheckResultDto("No field selected", PathCheckResultDto.BlockingForPublish);
        }

        let keyCount = 0;

        let errors = new Array<PathCheckResultDto>();

        pathSynchroMappingDtosRef.current.forEach((e: PathSynchroMappingDto) => {

            if (e.IsKey) {
                keyCount++;
            }

            if (e.TransformationLogicId == null) {
                errors.push(new PathCheckResultDto("Transformation missing for field " + GetFieldName(e.TargetFieldReferenceId) ?? "", PathCheckResultDto.BlockingForSave));
                return;
            }

            var availableTranformations = GetPathSynchroMappingCapabilities(e.TargetFieldReferenceId, e.SourceFieldReferenceId);

            if (availableTranformations === null) {
                errors.push(new PathCheckResultDto("No transformation available for field " + GetFieldName(e.TargetFieldReferenceId) ?? "", PathCheckResultDto.BlockingForSave));
                return;
            }

            if (availableTranformations?.TransformationLogicIds.indexOf(e.TransformationLogicId) === -1) {
                errors.push(new PathCheckResultDto("Bad transformation type for field " + GetFieldName(e.TargetFieldReferenceId) ?? "", PathCheckResultDto.BlockingForSave));
                return;
            }

            var transformationCapabilities = GetTransformationCapabilitiesDto(e.TransformationLogicId);

            if (transformationCapabilities === null) {
                errors.push(new PathCheckResultDto("Transformation not found for field " + GetFieldName(e.TargetFieldReferenceId) ?? "", PathCheckResultDto.BlockingForSave));
                return;
            }

            switch (transformationCapabilities?.TransformationCapabilitiesType) {
                case "TransformationOptions":
                    if (e.TransformationParameters === "") {
                        errors.push(new PathCheckResultDto("Tranformation setup missing for field " + GetFieldName(e.TargetFieldReferenceId) ?? "", PathCheckResultDto.BlockingForPublish));
                        return;
                    }
                    break;
            }
        });

        if (errors.length > 0) {
            return errors[0];
        }

        if (keyCount === 0) {
            return new PathCheckResultDto("No key selected", PathCheckResultDto.BlockingForPublish);
        }

        return new PathCheckResultDto("", PathCheckResultDto.Ok);
    }

    function save(publish: boolean): void {
        if (pathSynchroDetail === null || pathSynchroDetail.Source === null || pathSynchroDetail.Target === null) {
            alert("ERROR");
            return;
        }

        setPathSynchroDetail((previous) => {
            if (previous === null) {
                return previous;
            }

            return new GetPathSynchroDetailResult(
                previous.Id,
                previous.Name,
                previous.Source,
                previous.Target,
                pathSynchroMappingDtosRef.current,
                previous.Schedule,
                previous.Setup,
                previous.LastVersionId,
                previous.PublishedVersionId);
        });

        let checkResult = CheckBeforeSaveAndPublish();

        if (checkResult.Level === PathCheckResultDto.BlockingForSave) {
            if (messageFormHandleRef.current != null) {
                messageFormHandleRef.current.Open("Impossible to save : " + checkResult.Message, WarningMessage);
            }
            return;
        }

        if (publish) {
            if (checkResult.Level === PathCheckResultDto.BlockingForPublish) {
                if (messageFormHandleRef.current != null) {
                    messageFormHandleRef.current.Open("Impossible to save and publish : " + checkResult.Message, WarningMessage);
                }
                return;
            }
        }

        PathSynchroApi.UpdatePathSynchroDetailAsync(
            pathId,
            pathSynchroDetail.Name,
            pathSynchroMappingDtosRef.current,
            pathSynchroDetail.Source.DataSourceId > 0 ? pathSynchroDetail.Source.DataSourceId : null,
            pathSynchroDetail.Source.DatabaseReferenceId > 0 ? pathSynchroDetail.Source.DatabaseReferenceId : null,
            pathSynchroDetail.Source.TableReferenceId > 0 ? pathSynchroDetail.Source.TableReferenceId : null,
            pathSynchroDetail.Target.DataSourceId > 0 ? pathSynchroDetail.Target.DataSourceId : null,
            pathSynchroDetail.Target.DatabaseReferenceId > 0 ? pathSynchroDetail.Target.DatabaseReferenceId : null,
            pathSynchroDetail.Target.TableReferenceId > 0 ? pathSynchroDetail.Target.TableReferenceId : null,
            pathSynchroDetail.Schedule,
            pathSynchroDetail.Setup,
            publish)
            .then((updateResult) => { if (updateResult.IsFailed()) { alert("ERROR"); } else { if (publish) { setIsLaunchable(true); } } });

        synchroMappingFieldsRef.current?.Save();

        setChangeInprogress(false);

        setToastConfig({
            ...toastConfig,
            isVisible: true,
            type: 'success',
            message: publish ? 'Path saved and published' : 'Path saved as draft',
            withRedirect: false
        });

    }

    const onHiding = useCallback(() => {
        setToastConfig({
            ...toastConfig,
            isVisible: false,
        });

        if (toastConfig.withRedirect) {
            navigate("/paths");
        }

    }, [toastConfig, navigate]);

    function cancel(): void {

        if (changeInprogress) {
            setIsOpen(true);
        }
        else {
            navigate("/paths");
        }
    }

    function cancelFromModal(): void {

        setIsOpen(false);
        navigate("/paths");
    }

    function saveDraftFromModal(): void {

        setIsOpen(false);
        save(false);
        navigate("/paths");
    }

    function changePage(index: number): void {
        setCurrentTab(index);
    };

    function back() {
        setCurrentTab(currentTab - 1);
    }

    function next() {
        setCurrentTab(currentTab + 1);
    }

    if (pathSynchroDetail === null) {
        return (<div>Waiting</div>);
    }

    return (
        <React.Fragment>
            <div style={{ width: '100%', height: '100%', padding: 24, flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start', display: 'inline-flex' }}>
                <div style={{ alignSelf: 'stretch', height: '100%', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'flex' }}>
                    <div style={{ alignSelf: 'stretch', justifyContent: 'space-between', alignItems: 'center', display: 'inline-flex', minHeight: '49px' }}>
                        {editNameInprogress === null ?
                            <div style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 20, display: 'flex' }}>
                                <div style={{ color: '#E3E3E8', fontSize: 24, fontFamily: 'Manrope', fontWeight: '600', lineHeight: '36px', letterSpacing: 0.48, wordWrap: 'break-word' }}>
                                    {pathSynchroDetail?.Name ?? "???"}
                                </div>
                                <img src="svgs/edit.svg" alt="circle" style={{ cursor: 'pointer' }} onClick={() => setEditNameInprogress(pathSynchroDetail?.Name ?? "???")} />
                            </div> :
                            <div style={{ width: '50%', justifyContent: 'flex-start', alignItems: 'center', gap: 10, display: 'flex' }}>
                                <TextBox style={{ width: '50%' }} defaultValue={pathSynchroDetail?.Name ?? "???"} onValueChange={(e) => setEditNameInprogress(e)} />
                                <Button icon="check" onClick={() => handleNameChange()} />
                                <Button icon="close" onClick={() => setEditNameInprogress(null)} />
                            </div>}
                        <Button text="Close" type="normal" stylingMode="text" onClick={() => cancel()} />
                    </div>
                    <Tabs width='100%' stylingMode='secondary' onSelectedIndexChange={changePage} selectedIndex={currentTab} >
                        <Item text="Source">
                        </Item>
                        <Item text="Target">
                        </Item>
                        <Item text="Mapping">
                        </Item>
                        <Item text="Setup">
                        </Item>
                        <Item text="Checking">
                        </Item>
                    </Tabs>
                    {(currentTab === 0) ? <SourceSelection Direction="Source" TableMapping={pathSynchroDetail.Source ?? null} SetTableMapping={handleTableMappingSourceChange} /> : ""}
                    {(currentTab === 1) ? <SourceSelection Direction="Target" TableMapping={pathSynchroDetail.Target ?? null} SetTableMapping={handleTableMappingTargetChange} /> : ""}
                    {(currentTab === 2) ? <SynchroMappingFields
                        ref={synchroMappingFieldsRef}
                        Mappings={pathSynchroDetail?.Mappings ?? []}
                        MappingChanges={dataChangesRef.current}
                        SetMappingChanges={handleDataChanges}
                        SourceFields={sourceFields}
                        TargetFields={targetFields}
                        Capabilities={capabilities}
                        TransformationsCapabilities={transformationsCapabilities}
                        Mapping={mapping} /> : ""}
                    {(currentTab === 3) ? <SynchroSetup PathId={pathId} DataSourceTargetId={pathSynchroDetail.Target?.DataSourceId ?? null} PathSynchroSetup={pathSynchroDetail.Setup} SetPathSynchroSetup={handlePathSynchroSetupChange} PathSchedule={pathSynchroDetail.Schedule} SetPathSchedule={handlePathSynchroScheduleChange} /> : ""}
                    {(currentTab === 4) ? <SynchroResume PathId={pathId} IsLaunchable={isLaunchable} /> : ""}
                </div>
                <div style={{ width: '100%', height: 64, position: 'absolute', bottom: 32, left: 0, display: 'flex', justifyContent: 'space-between', padding: '16px 24px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button icon='back' text="Back to previous step" type="normal" stylingMode="text" onClick={() => back()} disabled={currentTab === 0} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 24 }}>
                        <Button text="Save as draft" type="normal" stylingMode="text" onClick={() => save(false)} />
                        {currentTab === 4 ?
                            <Button text="Save and publish" type="default" stylingMode="contained" onClick={() => save(true)} /> :
                            <Button text="Continue to next step" type="default" stylingMode="contained" onClick={() => next()} />}
                    </div>
                </div>
            </div>
            <ChangeInProgressForm IsOpen={isOpen} SetIsOpen={setIsOpen} Ok={() => saveDraftFromModal()} Cancel={() => cancelFromModal()} />
            <MessageForm ref={messageFormHandleRef} />
            <Toast
                visible={toastConfig.isVisible}
                message={toastConfig.message}
                type={toastConfig.type}
                onHiding={onHiding}
                displayTime={1000}
            />
        </React.Fragment>
    )
}
