import React, { useCallback, useState, useEffect } from 'react';
import { DataGrid, Selection, Column, FilterRow, Scrolling, SearchPanel } from 'devextreme-react/data-grid';

import CustomStore from 'devextreme/data/custom_store';
import GlobalLoader from '../loader/GlobalLoader';

import CustomerApi from '../../api/CustomerApi';

import CustomerListDto from '../../classes/dtos/accountmodule/CustomerListDto';

import './CustomerGrid.css';

interface CustomerGridProps {
    //jobRuns: Array<JobRunDto> | null;
    OnClick(jobId: number, pathId: number, pathTypeId: number): void;
}

const CustomerGrid: React.FC<CustomerGridProps> = ({ /*jobRuns,*/ OnClick }) => {

    const [customers, setCustomers] = useState<Array<CustomerListDto> | null>(null);

    const refresh = useCallback(() => {
        if (!customers) {
            CustomerApi.GetCustomerListAsync().then((allCustomers: any) => {
                if (allCustomers.IsFailed()) {
                    // TODO: Handle failure case (e.g., logout)
                } else {
                    setCustomers(allCustomers.Result);
                }
            });
        }
    }, [customers]);

    // UseEffect to refresh when component mounts
    useEffect(() => {
        refresh();
    }, [refresh]);

    const customDataSource = new CustomStore({
        key: 'Id',
        load: () => {
            return customers || [];
        }
    });

    const SwitchCustomer = (cellData: DataGridTypes.ColumnCellTemplateData) => {
        CustomerApi.SwitchCustomerAsync(cellData.data.Id)
            .then(() => setCustomers(null));
    }

    const renderSwitchCustomerCommand = (cellData: DataGridTypes.ColumnCellTemplateData) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
                {(cellData.data.IsCurrent) ?
                    <img width={22} height={22} style={{ padding: '0px' }} src="svgs/circle.svg" alt="active" /> :
                    <img width={22} height={22} style={{ padding: '0px', cursor: 'pointer' }} src="svgs/play.svg" alt="inactive" onClick={() => SwitchCustomer(cellData)}/>}
            </div>
        );
    }

    if (!customers) {
        return <GlobalLoader />
    }

    return (
        <div className={"BlockCard-CustomerGrid"}>
            <div className={"BlockCard-CustomerGridHeader"}>
                <h2>{"All your companies"}</h2>
            {/*    <Button icon="plus" width={140} text="Invite user" type="default" stylingMode="contained" onClick={inviteUser} />*/}
            </div>
            <DataGrid
                id="dataGrid"
                dataSource={customDataSource}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={false}
                hoverStateEnabled={true}
                height='calc(100vh - 400px)'
            >
                <Scrolling mode="infinite" />
                <FilterRow visible={false} />
                <SearchPanel visible={false} />
                <Column caption="NAME" dataField="Name" dataType="string" allowSorting={false} />
                <Column width={120} caption="STATUS" dataField="StatusLabel" dataType="string" allowSorting={false}  />
                <Column width={120} caption="OWNER" dataField="IsOwner" dataType="boolean" allowSorting={false} />
                <Column width={120} caption="CURRENT" dataField="IsCurrent" dataType="boolean" allowSorting={false} />
                <Column width={80} cellRender={renderSwitchCustomerCommand} allowEditing={false} allowFiltering={false} />
            </DataGrid>
        </div>
    );
};

export default CustomerGrid;
