import React from 'react';
import { ScrollView } from 'devextreme-react/scroll-view';

import './PathCredentialGrid.scss';

export default function PathCredentialGrid() {
    return (
        <div className="path-credential-grid-container">
            <ScrollView>
                <div className="under-construction">
                    <img src="svgs/underconstruction.svg" alt="underconstruction" className="under-construction-image" />
                </div>
            </ScrollView>
        </div>
    );
}