import React, { useContext, useState, useEffect, useCallback } from 'react';

import { DataGrid, Selection, Column } from 'devextreme-react/data-grid';

import ProgressCell from '../progress-grid-cell/ProgressCell';

import NotificationContext from '../../NotificationContext';

import DashboardLastRunsDto from '../../classes/dtos/dashboardmodule/DashboardLastRunsDto';
import DashboardRunDto from '../../classes/dtos/dashboardmodule/DashboardRunDto';
import JobCommunicationProgressionDto from '../../classes/dtos/notificationmodule/JobCommunicationProgressionDto';

import DashboardApi from '../../api/DashboardApi';

import './LastRuns.css';

interface LastRunsProps {
    Details: DashboardLastRunsDto;
    OnClick(jobId: number, pathId: number, pathTypeId: number): void;
}

const LastRuns: React.FC<LastRunsProps> = ({ Details, OnClick }) => {

    const context = useContext(NotificationContext);

    if (!context) {
        throw new Error("LastRuns must be used within a NotificationContextProvider");
    }

    const [version, setVersion] = useState<number>(0);

    const [runs, setRuns] = useState<DashboardRunDto[]>(Details.Runs);

    const onSelectionChanged = useCallback(
        ({ selectedRowsData }: SelectionChangedEvent) => {
            if (selectedRowsData.length > 0) {
                OnClick(selectedRowsData[0].Id, selectedRowsData[0].PathId, selectedRowsData[0].PathTypeId);
            }
        },
        [OnClick]
    );

    const handleProgressionChange = useCallback((payload: string) => {
        let dto: JobCommunicationProgressionDto = JSON.parse(payload);

        setRuns((prevRuns) => {

            const line = prevRuns.find(e => e.Id === dto.JobId);

            if (line != null) {
                return prevRuns.map(run =>
                    run.Id === dto.JobId ? { ...run, Progress: dto.Progress } : run
                );
            } else {
                setVersion((prevVersion) => { return prevVersion + 1 });
            }

            return prevRuns;
        });
    }, []);

    useEffect(() => {
        if (version > 0) {
            DashboardApi.GetLastRunsAsync().then((result) => {
                if (result.IsSuccess() && result.Result?.Runs != null) {
                    setRuns(result.Result.Runs);
                }
            });
        }
    }, [version])

    useEffect(() => {
        context.notificationConnection.on('ProgressionChange', handleProgressionChange);

        return () => {
            context.notificationConnection.off('ProgressionChange', handleProgressionChange);
        };
    }, []);

    return (
        <React.Fragment>
            <DataGrid id="dataGridLastRuns" dataSource={runs} columnAutoWidth={true} repaintChangesOnly={true} hoverStateEnabled={true} onSelectionChanged={onSelectionChanged}>
                <Selection mode="single" />
                <Column caption="NAME" dataField="Name" dataType="string" allowSorting={false} />
                <Column caption="TYPE" dataField="PathTypeLabel" dataType="string" allowSorting={false} />
                <Column caption="STARTING TIME" dataField="Start" dataType="datetime" allowSorting={false} />
                <Column caption="PROGRESSION" dataField="Progress" cellRender={ProgressCell} allowSorting={false} />
            </DataGrid>
        </React.Fragment>
    );
}

export default LastRuns;