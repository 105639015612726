import React, { useState } from 'react';
import Tabs, { Item } from 'devextreme-react/tabs';

import UserGrid from '../../components/user-grid/UserGrid';
import CustomerGrid from '../../components/customer-grid/CustomerGrid';
import DataSourceGrid from '../../components/datasource-grid/DataSourceGrid';
import PathCredentialGrid from '../../components/path-credential-grid/PathCredentialGrid';

import './settings.scss';

export default function Settings() {

    const [currentTab, setCurrentTab] = useState<number>(0);

    function changePage(index: number): void {
        setCurrentTab(index);
    };

    return (
        <React.Fragment>
            <div className="settings-container">
                <div className={"settings-internal-container"}>
                    <div className={"settings-title-container"}>
                        <h2>{"Settings"}</h2>
                    </div>
                    <Tabs width='100%' stylingMode='secondary' onSelectedIndexChange={changePage} selectedIndex={currentTab} >
                        <Item text="Companies">
                        </Item>
                        <Item text="Users">
                        </Item>
                        <Item text="Data Sources">
                        </Item>
                        <Item text="Path credentials">
                        </Item>
                    </Tabs>
                    {(currentTab === 0) ? <CustomerGrid /> : ""}
                    {(currentTab === 1) ? <UserGrid /> : ""}
                    {(currentTab === 2) ? <DataSourceGrid /> : ""}
                    {(currentTab === 3) ? <PathCredentialGrid /> : ""}
                </div>
            </div>
        </React.Fragment>
    );
}