import React from 'react';
import PathType from '../../consts/pathmodule/PathType';
import { DataGridTypes } from 'devextreme-react/data-grid';
import PathSynchroJobResumeDto from '../../classes/dtos/jobmodule/PathSynchroJobResumeDto';

import './ResumeCell.scss';

const ResumeCell = (cellData: DataGridTypes.ColumnCellTemplateData) => {

    if (cellData.value === null) {
        return (<div>Empty</div>);
    }

    switch (cellData.value.PathType) {

        case PathType.DataSynchronization:

            let pathSynchroJobResumeDto: PathSynchroJobResumeDto = cellData.value as PathSynchroJobResumeDto;

            if (pathSynchroJobResumeDto === null) {
                return (<div>Synchro</div>);
            } else {

                return (<div>
                    <div>
                        {"Added : "} <span style={{ fontWeight: "bold", color: "green", marginRight: "12px" }}>{pathSynchroJobResumeDto.Added}</span>
                        {"Updated : "} <span style={{ fontWeight: "bold", color: "green", marginRight: "12px" }}>{pathSynchroJobResumeDto.Updated}</span>
                        {"Deleted : "} <span style={{ fontWeight: "bold", color: "green", marginRight: "12px" }}>{pathSynchroJobResumeDto.Deleted}</span>
                        {"Ignored : "} <span style={{ fontWeight: "bold", color: "green" }}>{pathSynchroJobResumeDto.Ignored}</span>
                    </div>
                </div>);
            }

        case PathType.Reporting:

            return (<div>Reporting</div>);
    }
};

export default ResumeCell;
