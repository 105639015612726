import React, { useCallback } from 'react';

import { ScrollView } from 'devextreme-react';

import TableMappingReadDto from '../../classes/dtos/pathcommonmodule/TableMappingReadDto';

import DataSourceSelectionDto from '../../classes/dtos/datasourcemodule/DataSourceSelectionDto';
import DatabaseSelectionDto from '../../classes/dtos/datastructuremodule/DatabaseSelectionDto';
import TableSelectionDto from '../../classes/dtos/datastructuremodule/TableSelectionDto';

import DataSourceSelection from './DataSourceSelection';
import DatabaseSelection from './DatabaseSelection';
import TableSelection from './TableSelection';

import './SourceSelection.css';

interface SourceSelectionProps {
    Direction: string;
    TableMapping: TableMappingReadDto | null;
    SetTableMapping(value: TableMappingReadDto): void;
}

const SourceSelection: React.FC<SourceSelectionProps> = ({ Direction, TableMapping, SetTableMapping }) => {

    const handleSetDataSourceSelected = useCallback((dataSourceSelectionDto: DataSourceSelectionDto) => {

        if (TableMapping !== null && dataSourceSelectionDto.DataSource !== null) {

            if (TableMapping.DataSourceId !== dataSourceSelectionDto.DataSource.Id) {

                var newTableMapping = new TableMappingReadDto(
                    dataSourceSelectionDto.DataSource.Id,
                    dataSourceSelectionDto.DataSource.Name,
                    0,
                    '',
                    0,
                    '');

                SetTableMapping(newTableMapping);
            }
        }

    }, [SetTableMapping, TableMapping]);

    const handleSetDatabaseSelected = useCallback((databaseSelectionDto: DatabaseSelectionDto) => {

        if (TableMapping !== null && databaseSelectionDto.Database !== null) {

            if (TableMapping.DatabaseReferenceId !== databaseSelectionDto.Database.Id) {

                var newTableMapping = new TableMappingReadDto(
                    TableMapping.DataSourceId,
                    TableMapping.DataSourceLabel,
                    databaseSelectionDto.Database.Id,
                    databaseSelectionDto.Database.Name,
                    0,
                    '');

                SetTableMapping(newTableMapping);
            }
        }

    }, [SetTableMapping, TableMapping]);

    const handleSetTableSelected = useCallback((tableSelectionDto: TableSelectionDto) => {

        if (TableMapping !== null && tableSelectionDto.Table !== null) {

            if (TableMapping.TableReferenceId !== tableSelectionDto.Table.Id) {

                var newTableMapping = new TableMappingReadDto(
                    TableMapping.DataSourceId,
                    TableMapping.DataSourceLabel,
                    TableMapping.DatabaseReferenceId,
                    TableMapping.DatabaseReferenceLabel,
                    tableSelectionDto.Table.Id,
                    tableSelectionDto.Table.Name);

                SetTableMapping(newTableMapping);
            }
        }

    }, [SetTableMapping, TableMapping]);

    return (
        <React.Fragment>
            <ScrollView style={{ width: '100%', height: 'calc(100vh - 320px)' }}>
                <div style={{ width: '100%', minHeight: 'calc(100vh - 320px)', height: 'auto', padding: '20px', position: 'relative', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
                    <div style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
                        <DataSourceSelection DataSourceId={TableMapping?.DataSourceId ?? 0} DataSourceLabel={TableMapping?.DataSourceLabel ?? "?????"} SetDataSourceSelected={handleSetDataSourceSelected} Direction={Direction} />
                        <div style={{ width: 1, height: 32, transformOrigin: '0 0', border: '1px #3D3D46 dotted' }}></div>
                        <DatabaseSelection DataSourceId={TableMapping?.DataSourceId ?? 0} DatabaseReferenceId={TableMapping?.DatabaseReferenceId ?? 0} DatabaseReferenceLabel={TableMapping?.DatabaseReferenceLabel ?? "?????"} SetDatabaseSelected={handleSetDatabaseSelected} />
                        <div style={{ width: 1, height: 32, transformOrigin: '0 0', border: '1px #3D3D46 dotted' }}></div>
                        <TableSelection DataSourceId={TableMapping?.DataSourceId ?? 0} DatabaseReferenceId={TableMapping?.DatabaseReferenceId ?? 0} TableReferenceId={TableMapping?.TableReferenceId ?? 0} TableReferenceLabel={TableMapping?.TableReferenceLabel ?? "?????"} SetTableSelected={handleSetTableSelected} Direction={Direction} />
                    </div>
                </div>
            </ScrollView>
        </React.Fragment>
    );
}

export default SourceSelection;