class DataSourceAuthenticationDetailDto {

    Label: string;
    Name: string;
    ToEnter: boolean;
    ToGenerate: boolean;
    ToStore: boolean;

    constructor(label: string, name: string, toEnter: boolean, toGenerate: boolean, toStore: boolean) {
        this.Label = label;
        this.Name = name;
        this.ToEnter = toEnter;
        this.ToGenerate = toGenerate;
        this.ToStore = toStore;
    }
}

export default DataSourceAuthenticationDetailDto;