import React, { useCallback, useState, useEffect, useRef } from 'react';
import { DataGrid, Selection, Column, FilterRow, Scrolling, SearchPanel } from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react';

import CustomStore from 'devextreme/data/custom_store';
import GlobalLoader from '../loader/GlobalLoader';

import UserInvitationForm, { UserInvitationFormHandle } from '../user-invitation/UserInvitationForm';

import UserApi from '../../api/UserApi';

import UserListDto from '../../classes/dtos/accountmodule/UserListDto';

import { useAuth } from '../../contexts/auth';

import './UserGrid.css';

interface UserGridProps {
    //jobRuns: Array<JobRunDto> | null;
    OnClick(jobId: number, pathId: number, pathTypeId: number): void;
}

const UserGrid: React.FC<UserGridProps> = ({ /*jobRuns,*/ OnClick }) => {

    const userInvitationFormHandleRef = useRef<UserInvitationFormHandle>(null);

    const [users, setUsers] = useState<Array<UserListDto> | null>(null);

    const [currentUserId] = useState<number>(useAuth().user?.UserId ?? 0);

    const [invitationAuthorized, setInvitationAuthorized] = useState<boolean>(false);
    
    function invited() {
        setUsers(null);
    }

    const refresh = useCallback(() => {
        if (!users) {
            UserApi.GetUserListAsync().then((allUsers: any) => {
                if (allUsers.IsFailed()) {
                    // TODO: Handle failure case (e.g., logout)
                } else {
                    var owner = allUsers.Result.find(e => e.Id === currentUserId && e.IsOwner) ?? null;
                    setInvitationAuthorized(owner);
                    setUsers(allUsers.Result);
                }
            });
        }
    }, [users]);

    // UseEffect to refresh when component mounts
    useEffect(() => {
        refresh();
    }, [refresh]);

    function inviteUser(): void {
        if (userInvitationFormHandleRef.current !== null) {
            userInvitationFormHandleRef.current.Open();
        }
    };

    const customDataSource = new CustomStore({
        key: 'Id',
        load: () => {
            return users || [];
        }
    });

    if (!users) {
        return <GlobalLoader />
    }

    return (
        <div className={"BlockCard-UserGrid"}>
            <div className={"BlockCard-UserGridHeader"}>
                <h2>{"All your users"}</h2>
                <Button icon="plus" width={140} text="Invite user" type="default" stylingMode="contained" onClick={inviteUser} disabled={!invitationAuthorized} />
            </div>
            <DataGrid
                id="dataGrid"
                dataSource={customDataSource}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={false}
                hoverStateEnabled={true}
                height='calc(100vh - 400px)'
            >
                <Scrolling mode="infinite" />
                <FilterRow visible={false} />
                <SearchPanel visible={false} />
                <Column caption="EMAIL" dataField="Email" dataType="string" allowSorting={false} />
                <Column caption="FIRSTNAME" dataField="FirstName" dataType="string" allowSorting={false} />
                <Column caption="LASTNAME" dataField="LastName" dataType="string" allowSorting={false} />
                <Column width={120} caption="OWNER" dataField="IsOwner" dataType="boolean" allowSorting={false} />
                <Column width={120} caption="STATUS" dataField="StatusLabel" dataType="string" allowSorting={false} />
            </DataGrid>
            <UserInvitationForm ref={userInvitationFormHandleRef} Invited={invited} />
        </div>
    );
};

export default UserGrid;
