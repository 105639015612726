import Settings from "./Settings";
import { AuthToken } from "./AuthToken";

import ProcessingResult from "../framework/ProcessingResult";

import UserData from "../classes/api/result/accountmodule/UserData";

import UserListDto from "../classes/dtos/accountmodule/UserListDto";

const UserApi = {

    EnsureStandardAccountAsync: async function (token: string): Promise<UserData> {
        const response = await fetch(Settings.api_url + "/User/EnsureStandardAccount", {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });

        if (response.status === 200) {
            var userData = await response.json();
            return new UserData(userData.userId, userData.customerId);
        }

        return new UserData(-1, -1);
    },

    GetUserListAsync: async function (): Promise<ProcessingResult<UserListDto[]>> {
        let authSession = await AuthToken.get();

        const response = await fetch(Settings.api_url + "/User/GetUsers", {
            method: "Get",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            }
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(data.users.map((e: { id: number; email: string; firstName: string; lastName: string; isOwner: boolean; status: number }) => new UserListDto(e.id, e.email, e.firstName, e.lastName, e.isOwner, e.status)));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());

    }
};

export default UserApi;